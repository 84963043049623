@charset "UTF-8";
/**
 * For calculations
 */
/**
 * Colors
 */
/**
 * Grid
 */
/**
 * Types
 */
/**
 * Spacing
 */
/**
 * Timing
 */
@import '../../../../../../../node_modules/flickity/css/flickity.css';
.btn, .comment-list .comment-meta a, .entry-content p .button,
.entry-content nav:not(.entries) .button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .comment-list .comment-meta a, .entry-content p .button,
  .entry-content nav:not(.entries) .button {
    transition: none;
  }
}
.btn:hover, .comment-list .comment-meta a:hover, .entry-content p .button:hover,
.entry-content nav:not(.entries) .button:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .comment-list .comment-meta a:focus, .entry-content p .button:focus,
.entry-content nav:not(.entries) .button:focus, .btn.focus, .comment-list .comment-meta a.focus, .entry-content p .focus.button,
.entry-content nav:not(.entries) .focus.button {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .comment-list .comment-meta a.disabled, .entry-content p .disabled.button,
.entry-content nav:not(.entries) .disabled.button, .btn:disabled, .comment-list .comment-meta a:disabled, .entry-content p .button:disabled,
.entry-content nav:not(.entries) .button:disabled {
  opacity: 0.65;
}
a.btn.disabled, .comment-list .comment-meta a.disabled, .entry-content p a.disabled.button,
.entry-content nav:not(.entries) a.disabled.button,
fieldset:disabled a.btn,
fieldset:disabled .comment-list .comment-meta a,
.comment-list .comment-meta fieldset:disabled a,
fieldset:disabled .entry-content p a.button,
.entry-content p fieldset:disabled a.button,
fieldset:disabled .entry-content nav:not(.entries) a.button,
.entry-content nav:not(.entries) fieldset:disabled a.button {
  pointer-events: none;
}

.btn-primary {
  color: #212529;
  background-color: #83a595;
  border-color: #83a595;
}
.btn-primary:hover {
  color: #fff;
  background-color: #6d9582;
  border-color: #678e7c;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 146, 133, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #212529;
  background-color: #83a595;
  border-color: #83a595;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #678e7c;
  border-color: #628775;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 146, 133, 0.5);
}

.btn-secondary, .entry-content p .button.selected,
.entry-content nav:not(.entries) .button.selected {
  color: #212529;
  background-color: #8ba6b1;
  border-color: #8ba6b1;
}
.btn-secondary:hover, .entry-content p .button.selected:hover,
.entry-content nav:not(.entries) .button.selected:hover {
  color: #fff;
  background-color: #7494a2;
  border-color: #6d8f9c;
}
.btn-secondary:focus, .entry-content p .button.selected:focus,
.entry-content nav:not(.entries) .button.selected:focus, .btn-secondary.focus, .entry-content p .focus.button.selected,
.entry-content nav:not(.entries) .focus.button.selected {
  box-shadow: 0 0 0 0.2rem rgba(123, 147, 157, 0.5);
}
.btn-secondary.disabled, .entry-content p .disabled.button.selected,
.entry-content nav:not(.entries) .disabled.button.selected, .btn-secondary:disabled, .entry-content p .button.selected:disabled,
.entry-content nav:not(.entries) .button.selected:disabled {
  color: #212529;
  background-color: #8ba6b1;
  border-color: #8ba6b1;
}
.btn-secondary:not(:disabled):not(.disabled):active, .entry-content p .button.selected:not(:disabled):not(.disabled):active,
.entry-content nav:not(.entries) .button.selected:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .entry-content p .button.selected:not(:disabled):not(.disabled).active,
.entry-content nav:not(.entries) .button.selected:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle, .entry-content p .show > .dropdown-toggle.button.selected,
.entry-content nav:not(.entries) .show > .dropdown-toggle.button.selected {
  color: #fff;
  background-color: #6d8f9c;
  border-color: #658997;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .entry-content p .button.selected:not(:disabled):not(.disabled):active:focus,
.entry-content nav:not(.entries) .button.selected:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .entry-content p .button.selected:not(:disabled):not(.disabled).active:focus,
.entry-content nav:not(.entries) .button.selected:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus, .entry-content p .show > .dropdown-toggle.button.selected:focus,
.entry-content nav:not(.entries) .show > .dropdown-toggle.button.selected:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 147, 157, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary, .comment-list .comment-meta a {
  color: #83a595;
  border-color: #83a595;
}
.btn-outline-primary:hover, .comment-list .comment-meta a:hover {
  color: #212529;
  background-color: #83a595;
  border-color: #83a595;
}
.btn-outline-primary:focus, .comment-list .comment-meta a:focus, .btn-outline-primary.focus, .comment-list .comment-meta a.focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 165, 149, 0.5);
}
.btn-outline-primary.disabled, .comment-list .comment-meta a.disabled, .btn-outline-primary:disabled, .comment-list .comment-meta a:disabled {
  color: #83a595;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .comment-list .comment-meta a:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .comment-list .comment-meta a:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle, .comment-list .comment-meta .show > a.dropdown-toggle {
  color: #212529;
  background-color: #83a595;
  border-color: #83a595;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .comment-list .comment-meta a:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .comment-list .comment-meta a:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus, .comment-list .comment-meta .show > a.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(131, 165, 149, 0.5);
}

.btn-outline-secondary, .entry-content p .button,
.entry-content nav:not(.entries) .button {
  color: #8ba6b1;
  border-color: #8ba6b1;
}
.btn-outline-secondary:hover, .entry-content p .button:hover,
.entry-content nav:not(.entries) .button:hover {
  color: #212529;
  background-color: #8ba6b1;
  border-color: #8ba6b1;
}
.btn-outline-secondary:focus, .entry-content p .button:focus,
.entry-content nav:not(.entries) .button:focus, .btn-outline-secondary.focus, .entry-content p .focus.button,
.entry-content nav:not(.entries) .focus.button {
  box-shadow: 0 0 0 0.2rem rgba(139, 166, 177, 0.5);
}
.btn-outline-secondary.disabled, .entry-content p .disabled.button,
.entry-content nav:not(.entries) .disabled.button, .btn-outline-secondary:disabled, .entry-content p .button:disabled,
.entry-content nav:not(.entries) .button:disabled {
  color: #8ba6b1;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .entry-content p .button:not(:disabled):not(.disabled):active,
.entry-content nav:not(.entries) .button:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .entry-content p .button:not(:disabled):not(.disabled).active,
.entry-content nav:not(.entries) .button:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle, .entry-content p .show > .dropdown-toggle.button,
.entry-content nav:not(.entries) .show > .dropdown-toggle.button {
  color: #212529;
  background-color: #8ba6b1;
  border-color: #8ba6b1;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .entry-content p .button:not(:disabled):not(.disabled):active:focus,
.entry-content nav:not(.entries) .button:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .entry-content p .button:not(:disabled):not(.disabled).active:focus,
.entry-content nav:not(.entries) .button:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus, .entry-content p .show > .dropdown-toggle.button:focus,
.entry-content nav:not(.entries) .show > .dropdown-toggle.button:focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 166, 177, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #83a595;
  text-decoration: none;
}
.btn-link:hover {
  color: #5c7f6f;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .comment-list .comment-meta a, .entry-content p .button,
.entry-content nav:not(.entries) .button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

@font-face {
  font-family: "Tisa Pro";
  src: url("/cdn/assets/fonts/tisa-pro/TisaPro-Ita.woff2") format("woff2"), url("/cdn/assets/fonts/tisa-pro/TisaPro-Ita.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Tisa Pro";
  src: url("/cdn/assets/fonts/tisa-pro/TisaPro-Medi.woff2") format("woff2"), url("/cdn/assets/fonts/tisa-pro/TisaPro-Medi.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Tisa Pro";
  src: url("/cdn/assets/fonts/tisa-pro/TisaPro-MediIta.woff2") format("woff2"), url("/cdn/assets/fonts/tisa-pro/TisaPro-MediIta.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Tisa Pro";
  src: url("/cdn/assets/fonts/tisa-pro/TisaPro-Bold.woff2") format("woff2"), url("/cdn/assets/fonts/tisa-pro/TisaPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Tisa Pro";
  src: url("/cdn/assets/fonts/tisa-pro/TisaPro-BoldIta.woff2") format("woff2"), url("/cdn/assets/fonts/tisa-pro/TisaPro-BoldIta.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Tisa Pro";
  src: url("/cdn/assets/fonts/tisa-pro/TisaPro.woff2") format("woff2"), url("/cdn/assets/fonts/tisa-pro/TisaPro.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
[aria-hidden=true] a {
  pointer-events: none !important;
  display: none !important;
}

iframe {
  border: none;
}

.social-buttons {
  display: flex;
  margin-left: -0.5em;
}
.social-buttons > * {
  flex: 0 0 auto;
}

.btn-outline-primary, .comment-list .comment-meta a {
  border-color: #e9ecef;
}
.btn-outline-primary:hover, .comment-list .comment-meta a:hover, .btn-outline-primary:focus, .comment-list .comment-meta a:focus {
  color: #fff;
  border-color: #83a595;
}

.u {
  text-decoration: underline;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: none;
  border-bottom: dotted 1px rgba(131, 165, 149, 0.64);
}
@media (prefers-color-scheme: dark) {
  abbr[data-original-title],
  abbr[title] {
    border-bottom: dotted 1px rgba(170, 194, 183, 0.64);
  }
}

@media (prefers-color-scheme: dark) {
  .dark-brighten {
    filter: brightness(1.6);
  }
}
.grecaptcha-badge {
  position: absolute;
  left: -9999px;
}

.entry-content p a,
.entry-content nav:not(.entries) a {
  text-decoration: underline;
  color: #495057;
}
@media (prefers-color-scheme: dark) {
  .entry-content p a,
  .entry-content nav:not(.entries) a {
    color: #aac2b7;
  }
}
.entry-content p .button.selected,
.entry-content nav:not(.entries) .button.selected {
  color: #fff;
}
.entry-content p .button + br + .button,
.entry-content nav:not(.entries) .button + br + .button {
  margin-top: 0.4em;
}

a.back-up {
  border: none !important;
}

.pager {
  text-align: center;
  margin-top: 64px;
  margin-bottom: 64px;
}
.pager > div {
  padding: 0;
}
.pager.top {
  margin-top: 0;
}
.pager .page-numbers {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 0.25rem;
  font-family: "Tisa Pro", serif;
  background: #e9ecef;
  color: #495057;
  border-color: #e9ecef;
  min-width: 2.4em;
  text-decoration: none;
  transition: all 0.2s;
  border: solid 1px #e9ecef;
}
@media (prefers-color-scheme: dark) {
  .pager .page-numbers {
    background: #343a40;
    color: #dee2e6;
    border-color: #343a40;
  }
}
.pager .page-numbers.dots {
  background: transparent;
}
.pager .page-numbers.current {
  background: transparent;
  border-color: #343a40;
  border: solid 1px #e9ecef;
}
@media (prefers-color-scheme: dark) {
  .pager .page-numbers.current {
    border-color: #343a40;
  }
}
.pager a.page-numbers:hover {
  background: #dee2e6;
  border-color: #dee2e6;
}
@media (prefers-color-scheme: dark) {
  .pager a.page-numbers:hover {
    background: #495057;
    border-color: #495057;
  }
}

html.nav-open {
  height: 100vh;
  overflow: hidden;
}
html.nav-open .site-content-contain {
  position: relative;
  pointer-events: none;
  overflow: hidden;
}

#sitemap-menu > ul {
  padding: 0;
}
#sitemap-menu ul ul {
  padding-left: 30px;
}
#sitemap-menu li {
  list-style: none;
  margin-top: 0.5em;
}
#sitemap-menu li:before {
  content: "- ";
  opacity: 0.44;
}
#sitemap-menu > ul > li > a {
  color: #212529;
  font-weight: 700;
}
@media (prefers-color-scheme: dark) {
  #sitemap-menu > ul > li > a {
    color: #fff;
  }
}
#sitemap-menu .sub-menu li:before {
  content: "– ";
}
#sitemap-menu .sub-menu li a {
  font-weight: 700;
  color: #4f6b76;
}
@media (prefers-color-scheme: dark) {
  #sitemap-menu .sub-menu li a {
    color: #b4c5cc;
  }
}
#sitemap-menu .sub-menu .sub-menu li:before {
  content: "— ";
}
#sitemap-menu .sub-menu .sub-menu li a {
  font-weight: 400;
  opacity: 0.84;
}

.menu-shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: -1;
  background: rgba(0, 0, 0, 0.24);
  visibility: hidden;
  opacity: 0;
}
html.ready .menu-shade {
  transition: visibility 0s linear 0.2s, z-index 0s linear 0.2s, opacity 0.2s linear;
}
html.ready.nav-open .menu-shade {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear, z-index 0s linear, opacity 0.2s linear;
  z-index: 900;
}

#opener {
  transition: all 0.2s;
}
#opener:before, #opener:after {
  transition: transform 0.2s;
}
html.nav-open #opener:before {
  transform: rotate(45deg) translate(2px, 2px);
}
html.nav-open #opener:after {
  transform: rotate(-45deg) translate(3px, -3px);
}
body.luminous-open #opener {
  opacity: 0;
}

#access li,
#footer-access li {
  list-style: none;
}
#access a,
#footer-access a {
  color: #212529;
}
@media (prefers-color-scheme: dark) {
  #access a,
  #footer-access a {
    color: #fff;
  }
}
#access .menu > ul,
#footer-access .menu > ul {
  padding: 0;
  font-family: "Tisa Pro", serif;
}
#access .menu > ul > li > a, #access .menu > ul > li.same-level-subchildren .sub-menu li,
#footer-access .menu > ul > li > a,
#footer-access .menu > ul > li.same-level-subchildren .sub-menu li {
  line-height: 1.3;
}
#access .menu > ul > li > a a, #access .menu > ul > li.same-level-subchildren .sub-menu li a,
#footer-access .menu > ul > li > a a,
#footer-access .menu > ul > li.same-level-subchildren .sub-menu li a {
  font-weight: 400;
  color: #212529;
}
@media (prefers-color-scheme: dark) {
  #access .menu > ul > li > a a, #access .menu > ul > li.same-level-subchildren .sub-menu li a,
  #footer-access .menu > ul > li > a a,
  #footer-access .menu > ul > li.same-level-subchildren .sub-menu li a {
    color: #fff;
  }
}
#access .menu > ul > li + li,
#footer-access .menu > ul > li + li {
  margin-top: 32px;
}
#access .menu > ul .sub-menu,
#footer-access .menu > ul .sub-menu {
  font-size: 1rem;
  padding: 0;
}
#access .menu > ul .sub-menu li,
#footer-access .menu > ul .sub-menu li {
  margin-top: 12px;
  line-height: 1.2;
}
#access .menu > ul .sub-menu a,
#footer-access .menu > ul .sub-menu a {
  color: #8ba6b1;
}
#access .menu > ul .sub-menu .sub-menu,
#footer-access .menu > ul .sub-menu .sub-menu {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.skip-link:focus {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  display: block;
  height: auto;
  line-height: normal;
  padding: 8px 16px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  position: absolute;
  left: 5px;
}
@media (prefers-color-scheme: dark) {
  .skip-link:focus {
    background-color: #1c1f25;
  }
}

#access {
  background: #fff;
  max-height: 0;
  max-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
  min-height: 100vh;
  padding: 27px 30px 64px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
@media (prefers-color-scheme: dark) {
  #access {
    background: #1c1f25;
  }
}
html.js #access {
  transition: transform 0.4s, opacity 0.4s, visibility 0s linear 0.4s;
}
html.nav-open #access {
  -webkit-overflow-scrolling: touch;
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
  transition: transform 0.4s, opacity 0.4s, visibility 0s;
}
#access .search-form {
  margin: 64px -15px 0;
  padding: 0 15px;
  overflow: hidden;
}
#access #toc {
  font-family: "Tisa Pro", serif;
  margin-bottom: 64px;
}
#access #toc .toc-head {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 1em;
  margin-bottom: 1em;
}
#access #toc .toc-action {
  font-size: 0.875rem;
  font-style: italic;
}
#access #toc nav {
  padding-left: 15px;
}
#access #toc ol {
  padding: 0;
  margin: 0;
}
#access #toc li {
  margin-top: 15px;
  line-height: 1.4;
}
#access #toc li:before {
  content: "– ";
  color: #b4c5cc;
}
@media (prefers-color-scheme: dark) {
  #access #toc li:before {
    color: #4f6b76;
  }
}
#access .around-here {
  display: flex;
  flex-direction: column;
}
#access .around-here .previous a,
#access .around-here .next a {
  display: block;
  margin-bottom: 64px;
  font-family: "Tisa Pro", serif;
  font-weight: 500;
  line-height: 1.2;
}
#access .around-here .sup-head {
  font-weight: 400;
  margin-bottom: 1.8em;
}
#access a:hover {
  text-decoration: none;
}

.search-form {
  margin-top: 0;
}

.breadcrumb a {
  transition: color 0.2s;
}

.breadcrumb a:focus,
.breadcrumb a:hover {
  text-decoration: none;
  color: #000;
}
@media (prefers-color-scheme: dark) {
  .breadcrumb a:focus,
  .breadcrumb a:hover {
    color: #fff;
  }
}

blockquote {
  border-left: solid 5px #c8d7d0;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: -30px;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 30px;
  font-family: "Tisa Pro", serif;
  font-size: 1.25rem;
  color: #495057;
  line-height: 1.4;
  font-weight: 300;
}
@media (prefers-color-scheme: dark) {
  blockquote {
    border-left-color: #27352f;
  }
}
@media (prefers-color-scheme: dark) {
  blockquote {
    color: #aac2b7;
  }
}
blockquote.important-quote {
  font-size: 1.5rem;
}
blockquote strong {
  font-weight: 500;
}
blockquote cite {
  display: block;
  margin-bottom: 1em;
  font-size: 0.9rem;
}

hr {
  display: none;
}

.wp-smiley {
  margin-top: -0.3em;
}

#branding .hgroup a {
  text-decoration: none;
}

a {
  word-wrap: break-word;
}

.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

h1 a:hover, h1 a:focus, h2 a:hover, h2 a:focus, h3 a:hover, h3 a:focus, h4 a:hover, h4 a:focus, h5 a:hover, h5 a:focus {
  text-decoration: none;
}

h2 {
  position: relative;
}

.short-series .mc + p,
.short-series .mc + blockquote,
.short-series p + .mc,
.short-series blockquote + .mc {
  margin-top: 3.5rem;
}
.short-series .mc:not(.has-legend) + .mc {
  margin-top: 3.5rem;
  margin-top: 25vh;
}
.short-series .entry-content p {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.75rem;
}

.search-form {
  position: relative;
  z-index: 998;
}
.search-form.static {
  z-index: initial;
}
.search-form label {
  font-family: "Tisa Pro", serif;
  font-weight: 300;
  margin-top: 0.4rem;
}
.search-form .search-field {
  width: 100%;
  width: calc( 100% + 30px );
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0 -15px;
  resize: none;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  border-radius: 0;
  background: inherit;
  color: inherit;
}
@media (prefers-color-scheme: dark) {
  .search-form .search-field {
    background: transparent;
    color: #fff;
  }
}
.search-form .search-field:focus {
  outline: none;
  box-shadow: none;
  border-color: #83a595;
}
.search-form button {
  position: absolute;
  right: -0.3rem;
  bottom: 0.5rem;
  width: 30px;
  height: 30px;
  -webkit-appearance: none;
  border: none;
  background: transparent;
}

.search-form-layover {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 997;
  pointer-events: none;
}
.search-form-layover.show {
  opacity: 0.94;
  pointer-events: all;
}

.search-form-results {
  position: relative;
  z-index: 998;
}
.search-form-results .entries ol {
  padding: 30px 0;
}
.search-form-results .entries .info {
  padding-left: 15px;
}
.search-form-results .entries .title {
  font-size: 1rem;
}
.search-form-results .entries .content {
  font-size: 0.875rem;
}
#reading-progress {
  position: sticky;
  top: 0;
  z-index: 999;
  text-align: right;
  margin: 0 -15px;
  pointer-events: none;
}

#reading-progress-bar {
  width: 100%;
  height: 3px;
  position: relative;
}
#reading-progress-bar:after {
  content: "";
  display: block;
  transition: transform 0.2s;
  width: 100%;
  height: 100%;
  background: #83a595;
  position: absolute;
  transform: scale(calc(var(--content-data-offset, 0) * 1), 1);
  transform-origin: left;
  top: 0;
  left: 0;
}

footer#colophon {
  background: "color-secondary-100";
  margin-top: 64px;
  padding: 64px 15px;
  font-size: 0.875rem;
}
@media (prefers-color-scheme: dark) {
  footer#colophon {
    background: #1c1f25;
  }
}
@media (prefers-color-scheme: dark) {
  footer#colophon {
    border-top: solid 1px #2d3d43;
  }
}
footer#colophon .menu > ul {
  font-size: 0.875rem;
}
footer#colophon .menu > ul li {
  line-height: 1.2;
}
footer#colophon .menu > ul > li > a,
footer#colophon .menu > ul li.same-level-subchildren .sub-menu li a {
  font-weight: 400;
  color: #1f2a2f;
}
@media (prefers-color-scheme: dark) {
  footer#colophon .menu > ul > li > a,
  footer#colophon .menu > ul li.same-level-subchildren .sub-menu li a {
    color: #f1f4f5;
  }
}
footer#colophon .menu > ul .sub-menu {
  font-size: 0.875rem;
}
footer#colophon .credits {
  font-family: "Tisa Pro", serif;
  margin-top: 30px;
  font-size: 0.8rem;
  color: #1f2a2f;
  font-weight: 300;
}
@media (prefers-color-scheme: dark) {
  footer#colophon .credits {
    color: #f1f4f5;
  }
}
footer#colophon .credits a {
  color: #1f2a2f;
}
@media (prefers-color-scheme: dark) {
  footer#colophon .credits a {
    color: #f1f4f5;
  }
}
footer#colophon .credits strong {
  font-weight: 300;
}

.carousel,
.flickity-viewport {
  transition: height 0.2s;
}

.mc {
  margin: 30px -30px 0;
}
.mc:not(.has-legend) + .mc {
  margin-top: 2px;
}
.mc + .mc {
  margin-top: 0;
}
.mc .legende {
  margin: 15px 30px 30px;
  font-size: 0.875rem;
  line-height: 1.2;
  border-left: solid 1px #e9ecef;
  padding: 0.4em 18px;
  color: #343a40;
}
@media (prefers-color-scheme: dark) {
  .mc .legende {
    border-left-color: #343a40;
  }
}
@media (prefers-color-scheme: dark) {
  .mc .legende {
    color: #adb5bd;
  }
}
.mc .legende p {
  margin: 0;
}
.mc .legende p + p {
  margin-top: 1em;
}
.mc + p {
  margin-top: 32px;
}
.mc.w-auto {
  width: auto !important;
}
.mc.clean .photo {
  box-shadow: none !important;
}

.wp-block-embed + p {
  margin-top: 32px;
}

.wp-block-embed {
  margin: 30px -30px 0;
}
.mc + .wp-block-embed {
  margin-top: 0;
}
.mc:not(.has-legend) + .wp-block-embed {
  margin-top: 2px;
}
.wp-block-embed .video {
  position: relative;
}
.wp-block-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
}
@media (prefers-color-scheme: dark) {
  .wp-block-embed iframe {
    box-shadow: 0 0 7.5px rgba(0, 0, 0, 0.24);
  }
}

.rp-max-940,
.mcf.w-940 {
  max-width: 940px;
  flex-basis: 940px;
}

.rp-max-700,
.mcf.w-700 {
  max-width: 700px;
  flex-basis: 700px;
}

.rp-max-720,
.mcf.w-720 {
  max-width: 720px;
  flex-basis: 720px;
}

.rp-max-350,
.mcf.w-350 {
  max-width: 350px;
  flex-basis: 350px;
}

.rp-max-233,
.mcf.w-233 {
  max-width: 233px;
  flex-basis: 233px;
}

.rp-max-235,
.mcf.w-235 {
  max-width: 235px;
  flex-basis: 235px;
}

.rp-max-313,
.mcf.w-313 {
  max-width: 313px;
  flex-basis: 313px;
}

.rp-max-470,
.mcf.w-470 {
  max-width: 470px;
  flex-basis: 470px;
}

.rp-max-544,
.mcf.w-544 {
  max-width: 544px;
  flex-basis: 544px;
}

.rp-max-485,
.mcf.w-485 {
  max-width: 485px;
  flex-basis: 485px;
}

.rp-max-215,
.mcf.w-215 {
  max-width: 215px;
  flex-basis: 215px;
}

.rp-max-651,
.mcf.w-651 {
  max-width: 651px;
  flex-basis: 651px;
}

.rp-max-289,
.mcf.w-289 {
  max-width: 289px;
  flex-basis: 289px;
}

.rp-max-684,
.mcf.w-684 {
  max-width: 684px;
  flex-basis: 684px;
}

.rp-max-256,
.mcf.w-256 {
  max-width: 256px;
  flex-basis: 256px;
}

.rp-max-215,
.mcf.w-215 {
  max-width: 215px;
  flex-basis: 215px;
}

.rp-max-483,
.mcf.w-483 {
  max-width: 483px;
  flex-basis: 483px;
}

.rp-max-140,
.mcf.w-140 {
  max-width: 140px;
  flex-basis: 140px;
}

.rp-max-420,
.mcf.w-420 {
  max-width: 420px;
  flex-basis: 420px;
}

.rp-max-938,
.mcf.w-938 {
  max-width: 938px;
  flex-basis: 938px;
}

.rp-max-288,
.mcf.w-288 {
  max-width: 288px;
  flex-basis: 288px;
}

.rp-max-650,
.mcf.w-650 {
  max-width: 650px;
  flex-basis: 650px;
}

.rp-max-349,
.mcf.w-349 {
  max-width: 349px;
  flex-basis: 349px;
}

.photo {
  display: flex;
}
@media (prefers-color-scheme: dark) {
  .photo:not(.float), .photo.float .pictbox {
    box-shadow: 0 0 7.5px rgba(0, 0, 0, 0.24);
  }
}
.photo .pictbox {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}
.photo.float .pictbox {
  position: relative;
  margin-right: 2px;
  overflow: hidden;
}
.photo.float .pictbox:last-child {
  margin-right: 0;
}
.photo.float .img {
  overflow: visible;
}
.photo.float .img img {
  max-width: 101%;
}
.photo.center {
  margin-left: 30px;
  margin-right: 30px;
}

.mc.float .photos {
  display: flex;
}
.mc.float .mcf {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 1px;
}
.mc.float .mcf:first-child {
  margin-left: 0;
}
.mc.float .mcf:last-child {
  margin-right: 0;
}
.mc.float .img {
  overflow: visible;
  border-radius: 0;
}
.mc.float .img img {
  max-width: 101%;
}

.img {
  overflow: hidden;
  border-radius: 1px;
  background-color: #fafafa;
}
@media (prefers-color-scheme: dark) {
  .img {
    background-color: #181a1f;
  }
}
.img img {
  transition: filter 0.3s ease-in-out, transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
  filter: blur(0px) contrast(1) grayscale(0) brightness(1);
  transform: scale(1, 1);
}
.img img.lazy {
  filter: blur(20px) contrast(0.8) grayscale(0.1) brightness(1.2);
  transform: scale(1.1, 1.1);
  opacity: 1;
  z-index: 1;
  color: transparent;
}
.img img.lazy.done {
  opacity: 0;
}
.img.dl img {
  height: 100%;
}
.img.dp img {
  width: 100%;
}

@media (max-width: 767.98px) {
  .entries .featured .pict {
    margin-top: 0 !important;
    margin-bottom: 1.8em;
  }
}
.entries .featured .pict {
  flex: 0 0 100%;
  max-width: 100%;
}
.entries .featured .info {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.entries .pict {
  padding: 0;
  margin-top: 0.2em;
}
.entries h2,
.entries .h2 {
  margin-top: 64px;
  padding-top: 0;
  margin-bottom: 0;
}
.entries .h2sub {
  margin: 0;
  max-width: none;
  font-size: 0.875rem;
  color: #8ba6b1;
}
.entries ul, .entries ol {
  margin-top: 32px;
  padding: 0;
  list-style: none;
}
.entries li {
  flex: 0 0 100%;
  max-width: 100%;
}
.entries li + li {
  margin-top: 30px;
  border-top: solid 1px #e9ecef;
  padding-top: 30px;
}
@media (prefers-color-scheme: dark) {
  .entries li + li {
    border-top: solid 1px #343a40;
  }
}
.entries article.has-sup-head .pict {
  margin-top: 1.8rem;
}
.entry-footer .entries article.has-sup-head .pict {
  margin-top: 0;
}
.entries article > .row {
  align-items: flex-start;
}
.entries a {
  flex: 1 1 auto;
  color: #212529;
}
@media (prefers-color-scheme: dark) {
  .entries a {
    color: #fff;
  }
}
.entries a:hover {
  text-decoration: none;
}
.entries .mc {
  margin: 0;
  position: relative;
  width: auto !important;
}
.entries .mc,
.entries .mc img {
  border-radius: 1px;
}
.entries .mc:after {
  pointer-events: none;
  content: "";
  display: block;
  position: absolute;
  border-radius: 1px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
}
.entries .no-img .info {
  padding-left: 15px;
}
.entries .pict {
  flex: 0 0 25%;
  max-width: 25%;
}
.entries .info {
  flex: 0 0 75%;
  max-width: 75%;
  padding-left: 30px;
  transform: translate(0, 0);
  transition: all 0.2s;
}
.entries .footer-info {
  margin-top: 1em;
  padding-top: 1em;
  position: relative;
  font-size: 0.8rem;
  color: #8ba6b1;
  line-height: 1.4;
}
.entries .footer-info > span {
  display: block;
}
.entries .footer-info:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 64px;
  border-top: dotted 1px #e9ecef;
}
@media (prefers-color-scheme: dark) {
  .entries .footer-info:before {
    border-top-color: #343a40;
  }
}
@media (prefers-color-scheme: dark) {
  .entries .footer-info {
    color: #b4c5cc;
  }
}
.entries .title {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 500;
}
@media (max-width: 575.98px) {
  .entries .title {
    hyphens: auto;
  }
}
.entries .content {
  line-height: 1.4;
  color: #4f6b76;
  max-width: 512px;
}
@media (prefers-color-scheme: dark) {
  .entries .content {
    color: #b4c5cc;
  }
}
.entries .content p:last-child {
  margin-bottom: 0;
}
.entries li.coming-soon article {
  filter: brightness(0.84) contrast(0.84);
  opacity: 0.64;
}

.sup-head {
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #8ba6b1;
  letter-spacing: 0.1em;
  display: block;
  font-family: "Tisa Pro", serif;
  line-height: 1.4;
  margin-left: 0.1em;
}
@media (prefers-color-scheme: dark) {
  .sup-head {
    color: #b4c5cc;
  }
}

.sup-head {
  margin-bottom: 0.6em;
}

.map {
  margin-top: 60px;
  margin-bottom: 60px;
}

.tooltip {
  position: absolute;
  top: -999px;
  left: 0;
  z-index: 9999;
  max-width: 256px;
  max-width: calc( 100vw - 15px );
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.4s, opacity 0.2s linear 0s, margin-top 0.2s linear 0s;
  pointer-events: none;
}
.tooltip.small .inner {
  padding: 6px 9px;
  border-radius: 0.25rem;
  font-size: 0.8rem;
}
.tooltip .inner {
  font-family: "Tisa Pro", serif;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 12px 15px;
  background: #000;
  border-radius: 0.3rem;
  color: #fff;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.tooltip.reference, .tooltip.copyright {
  max-width: 256px;
  max-width: calc( 100vw - 15px );
  pointer-events: all;
  z-index: 900;
}
.tooltip.reference .inner, .tooltip.copyright .inner {
  background: #343a40;
  color: #fff;
  padding: 6px 15px;
}
.tooltip.reference .inner p, .tooltip.copyright .inner p {
  margin: 0.5em 0;
}
.tooltip.reference strong, .tooltip.copyright strong {
  color: #c1d2ca;
}
.tooltip.reference a, .tooltip.copyright a {
  color: #c1d2ca;
  text-decoration: underline;
}
.tooltip.reference a:hover, .tooltip.copyright a:hover {
  text-decoration: none;
}
.tooltip.reference .arrow:after, .tooltip.copyright .arrow:after {
  border-color: transparent transparent #343a40;
}
.tooltip.copyright.show {
  animation: flash 0.4s;
}
@keyframes flash {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.9;
  }
  40% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.tooltip .arrow {
  position: absolute;
}
.tooltip .arrow:after {
  display: block;
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #000;
}
.tooltip[data-popper-placement=bottom] {
  padding-top: 6px;
  margin-top: 2px;
}
.tooltip[data-popper-placement=bottom] .arrow {
  top: 2px;
}
.tooltip[data-popper-placement=top] {
  padding-bottom: 6px;
  margin-top: -2px;
}
.tooltip[data-popper-placement=top] .arrow {
  bottom: 2px;
}
.tooltip[data-popper-placement=top] .arrow:after {
  transform: rotate(180deg);
}
.tooltip.show {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 0.2s linear 0s;
}

.entries + .row {
  margin-top: 64px;
  font-size: 0.875rem;
}

.tooltip.copyright {
  pointer-events: all;
  font-size: 0.875rem;
}

#credit-references {
  font-size: 0.875rem;
}
#credit-references ol,
#credit-references ul {
  max-width: none;
  padding-left: 0;
  counter-reset: li;
}
#credit-references li {
  counter-increment: li;
  display: flex;
  align-items: flex-start;
}
#credit-references li:before {
  content: "#" counter(li);
  color: #8ba6b1;
}
#credit-references p {
  margin-top: 0;
}
#credit-references .reference-content {
  flex: 1 1 auto;
  min-width: 0;
}

.reference-link:before {
  content: " ";
}

.rating-sharing .h2 {
  margin-bottom: 0;
}

#rating-mod .star {
  font-size: 2rem;
  cursor: pointer;
  line-height: 0.9;
  display: inline-block;
  color: #c8d7d0;
  height: 1em;
}
#rating-mod .star.glow {
  opacity: 1;
  color: #83a595;
}
#rating-mod .rating:hover .star {
  color: #8ba6b1;
}
#rating-mod .rating:hover .star.glow {
  color: #2d3d43;
}

.social-button {
  display: block;
  margin: 0 0.5em;
}

.social-action {
  display: inline-block;
  padding: 0.6em 0.8em;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1;
  vertical-align: middle;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s;
}
.social-action:hover, .social-action:focus {
  color: #fff;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.24);
  text-decoration: none;
}

.social-action.facebook {
  background: #4666b8;
}

.social-action.twitter {
  background: #12b0ff;
}

.social-action.pinterest {
  background: #e32522;
}

.social-button .count {
  display: none;
}

.keep-reading .h2 {
  margin-bottom: 0;
}

.form-control:not([type=search]) {
  border: none;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.06), 0 1px 1px rgba(0, 0, 0, 0.12);
  font-size: 1rem;
  font-family: "Tisa Pro", serif;
  padding: 0.75rem 1rem;
  color: #000;
  background-color: #fff;
  transition: background-position 0.5s, box-shadow 0.5s, border-bottom 0.1s;
  background-position: center right -1.6225rem;
}
@media (prefers-color-scheme: dark) {
  .form-control:not([type=search]) {
    color: #fff;
    background-color: #101518;
  }
}

textarea.form-control {
  padding: 0.75rem 1rem;
}

input[type=submit]:not(.search-submit),
button[type=submit]:not(.search-submit) {
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Tisa Pro", serif;
  font-size: 0.875rem;
  line-height: 1;
  background: #8ba6b1;
  border: solid 2px #8ba6b1;
  width: 100%;
  height: 50px;
  border-radius: 0.25rem;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.06), 0 1px 1px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-weight: 700;
  transition: background-color 0.3s 0.1s, color 0.3s 0.1s, width 0.3s 0.1s, height 0.3s 0.1s, border-width 0.3s 0.1s, border-color 0.3s 0.1s, box-shadow 0.3s 0.1s, padding 0.3s 0.1s, border-radius 0.3s 0.1s;
}
@media (prefers-color-scheme: dark) {
  input[type=submit]:not(.search-submit),
  button[type=submit]:not(.search-submit) {
    background: #8ba6b1;
  }
}
input[type=submit]:not(.search-submit):hover, input[type=submit]:not(.search-submit).selected, input[type=submit]:not(.search-submit):focus, input[type=submit]:not(.search-submit):active:focus,
button[type=submit]:not(.search-submit):hover,
button[type=submit]:not(.search-submit).selected,
button[type=submit]:not(.search-submit):focus,
button[type=submit]:not(.search-submit):active:focus {
  background: #6d8f9c;
  border-color: #6d8f9c;
  outline: none;
}
input[type=submit]:not(.search-submit) span,
button[type=submit]:not(.search-submit) span {
  transition: opacity 0.3s 0.1s;
  white-space: nowrap;
}

.progress-button {
  position: relative;
  display: inline-block;
  text-align: center;
  min-width: 100%;
}

.progress-button svg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  transform-origin: 50% 0 0px;
}

.progress-button svg path {
  opacity: 0;
  fill: none;
}

.progress-button svg.progress-circle path {
  stroke: #83a595;
  stroke-width: 2;
  transition: opacity 0.1s, stroke-dashoffset 0.3s;
}

.loading.progress-button svg.progress-circle path {
  transition: opacity 0.1s 0.3s, stroke-dashoffset 0.1s 0.3s;
}

.loading.progress-button svg.progress-circle {
  transform-origin: 50% 50% 0px;
  top: 0;
  animation: spin 0.8s infinite linear;
}
@keyframes spin {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}

.progress-button svg.checkmark path,
.progress-button svg.cross path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 2;
  transition: opacity 0.1s, stroke-dashoffset 0.3s;
}
@media (prefers-color-scheme: dark) {
  .progress-button svg.checkmark path,
  .progress-button svg.cross path {
    stroke: #1c1f25;
  }
}

.progress-button svg.checkmark path {
  stroke: #fff;
}

/* Loading, success and error effects */
.loading.progress-button button {
  width: 50px !important; /* make a circle */
  height: 50px !important; /* make a circle */
  border-width: 2px;
  border-color: #ddd !important;
  background-color: transparent !important;
  color: #fff;
  box-shadow: none;
  transition: background-color 0.3s, color 0.3s, width 0.3s, height 0.3s, border-width 0.3s, border-color 0.3s, box-shadow 0.3s, padding 0.3s, border-radius 0.3s;
}

.loading.progress-button span {
  transition: opacity 0.15s;
}

.loading.progress-button span,
.success.progress-button span,
.error.progress-button span {
  opacity: 0; /* keep it hidden in all states */
}

.success.progress-button button {
  border-color: #8fcd7e !important;
  background-color: #8fcd7e !important;
  cursor: initial;
}

.error.progress-button button {
  border-color: #dc3545;
  background-color: #dc3545;
}
.error.progress-button button:hover, .error.progress-button button.selected, .error.progress-button button:focus, .error.progress-button button:active:focus {
  background: #bd2130;
  border-color: #bd2130;
}

.loading.progress-button button {
  border-radius: 25px;
}

.loading.progress-button svg.progress-circle path,
.success.progress-button svg.checkmark path,
.error.progress-button svg.cross path {
  opacity: 1;
}

.form-message {
  height: 0;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 0;
  max-width: none !important;
  transition: opacity 0.2s, padding 0.2s 0.2s, margin 0.2s 0.2s, line-height 0.2s 0.2s;
  background: #dc3545;
  color: #fff;
  font-size: 0.875rem;
  margin-top: 1em;
  padding: 0 1em;
  overflow: hidden;
  border-radius: 0.25rem;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.06), 0 1px 1px rgba(0, 0, 0, 0.12);
}
.form-message.visible {
  height: auto;
  padding: 1em 1.8em;
  opacity: 1;
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: inherit;
  transition: opacity 0.4s 0.4s, padding 0.4s, margin 0.4s, line-height 0.4s;
}
.form-message.success {
  background: #8fcd7e;
  color: #fff;
}
@media (prefers-color-scheme: dark) {
  .form-message.success {
    color: #1c1f25;
  }
}

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
  background-position: center right 0.6225rem;
  background-repeat: no-repeat;
  background-size: 1.125rem 1.125rem;
  box-shadow: 0 -1px 2px rgba(240, 173, 78, 0.36), 0 1px 1px rgba(240, 173, 78, 0.72) !important;
}

.d1 .form-control {
  transition-delay: 0.1s;
}

.d2 .form-control {
  transition-delay: 0.2s;
}

.d3 .form-control {
  transition-delay: 0.3s;
}

#reactions-comments {
  background: rgba(241, 244, 245, 0.64);
  margin-top: 3em;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 64px;
  margin-bottom: -64px;
}
@media (prefers-color-scheme: dark) {
  #reactions-comments {
    background: rgba(29, 35, 41, 0.64);
  }
}
@media (prefers-color-scheme: dark) {
  #reactions-comments {
    border-top: solid 1px #2d3d43;
  }
}
#reactions-comments #reply-title {
  display: none;
}

#comments .h2 {
  margin-bottom: 1em;
  padding-top: 0;
}

#respond .h2 {
  padding-top: 1em;
  margin-bottom: 1em;
}
#respond .comment-intro {
  margin-bottom: 2em;
}

form {
  margin-top: 2em;
}
form .form-group,
form .form-message {
  margin-left: -15px;
  margin-right: -15px;
}

.comment-list {
  font-size: 0.875rem;
  padding: 0;
}
.comment-list li {
  list-style: none;
}
.comment-list li + li {
  margin-top: 30px;
}
.comment-list a {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
}
.comment-list .comment-author {
  text-transform: uppercase;
  font-weight: 700;
}
.comment-list .comment-content {
  padding-left: 15px;
}
.comment-list .comment-content p {
  max-width: 540px;
}
.comment-list .comment-content p:last-child {
  margin-bottom: 0;
}
.comment-list .comment-meta {
  padding-left: 15px;
  margin-top: 1em;
}
.comment-list .comment-meta a {
  font-size: 0.8rem !important;
  color: #343a40;
}
@media (prefers-color-scheme: dark) {
  .comment-list .comment-meta a {
    color: #e9ecef;
  }
}

input,
textarea {
  font-family: "Noto Serif", serif;
}

.absorb-margin-bottom {
  margin-bottom: -64px;
}

.jumbo {
  margin-top: 30px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 30px 15px;
  background-color: #f1f4f5;
}
@media (prefers-color-scheme: dark) {
  .jumbo {
    background-color: #1f2a2f;
  }
}

.medium-zoom-overlay {
  z-index: 100;
  background: #fff !important;
}
@media (prefers-color-scheme: dark) {
  .medium-zoom-overlay {
    background: #1c1f25 !important;
  }
}

.medium-zoom-image--opened {
  z-index: 101;
}

.photography-gallery .mc {
  height: 100vh;
  padding: 15px 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
.photography-gallery .mc:not(.has-legend) + .mc {
  margin-top: 0;
}
.photography-gallery .mc .photo {
  height: 100%;
  box-shadow: none;
}
.photography-gallery .mc .pictbox {
  height: 100%;
  margin-left: -15px;
  margin-right: -15px;
}
.photography-gallery .mc .img.rp {
  padding: 0 15px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.photography-gallery .mc .img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: initial;
}
@media (prefers-color-scheme: dark) {
  .photography-gallery .mc .img img {
    box-shadow: 0 0 7.5px rgba(0, 0, 0, 0.24);
  }
}
.photography-gallery .img {
  background: transparent;
}
.photography-gallery .img .lazy {
  position: absolute !important;
  left: auto;
  right: auto;
}
.photography-gallery .img .lazy.fw {
  height: 100%;
  width: auto;
}
.photography-gallery .img .lazy.fh {
  width: 100%;
  height: auto;
}
.photography-gallery .legende {
  border: none;
  margin: 0;
  text-align: right;
  margin-left: auto;
  font-size: 0.875rem;
}

.flickity-button {
  background: transparent;
  width: 24px;
  height: 24px;
  transition: width 0.4s;
}
.flickity-button.previous {
  left: 0;
}
.flickity-button.next {
  right: 0;
}
.flickity-button:hover {
  width: 34px;
  background: transparent;
}

.flickity-button-icon {
  transition: fill 0.4s;
}

.jumbotron.slider {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
}

.carousel .timer {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  transition: opacity 0.5s, background-color 0.5s;
  background-color: #000;
  opacity: 0;
  border-radius: 0 1px 1px 0;
}
.carousel .timer.start {
  animation: carouselTimer 10s linear 0s 1 normal forwards;
  opacity: 1;
}
@keyframes carouselTimer {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.carousel:hover .timer {
  animation-play-state: paused;
  opacity: 0.5;
}
.carousel .flickity-viewport .img img {
  transition: none;
}
.carousel .mc.is-selected .legende {
  bottom: 16px;
  opacity: 1;
  transition: opacity 0.8s 1s, bottom 0.6s 1s;
}
.carousel .mc .legende {
  position: absolute;
  bottom: -16px;
  right: 16px;
  left: 16px;
  margin: 0;
  border: none;
  line-height: 1.4;
  opacity: 0;
  background: #fff;
  border-radius: 1px;
  padding: 30px;
  text-align: center;
  transition: opacity 0.4s 0s, bottom 0s 0.4s;
  will-change: opacity, bottom;
}
@media (prefers-color-scheme: dark) {
  .carousel .mc .legende {
    background: #212529;
  }
}
.carousel .mc .legende h1, .carousel .mc .legende h2 {
  margin: 0 0 0.5em;
  padding-top: 0;
}
.carousel .mc .legende a {
  font-family: "Tisa Pro", serif;
  padding: 8px;
  background: #83a595;
  display: block;
  color: #fff;
  border-radius: 1px;
  margin-top: 16px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s;
}
.carousel .mc .legende a:hover {
  background: #608473;
}
.carousel .mc .legende p {
  margin-left: auto;
  margin-right: auto;
}

.carousel .mc.slide-main .legende {
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 700;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: none;
}
.carousel .mc.slide-main h1 {
  font-size: 0;
  background: url("/cdn/assets/7.x/images/logo-full-black-en.svg") no-repeat center;
  width: 60vw;
  height: 50px;
  position: absolute;
  bottom: 70%;
  transition: bottom 0s 0.4s;
}
body.lang-fr .carousel .mc.slide-main h1 {
  background: url("/cdn/assets/7.x/images/logo-full-black-fr.svg") no-repeat center;
}
.carousel .mc.slide-main p {
  position: absolute;
  bottom: 30px;
  right: 24px;
  text-align: right;
  font-family: "Tisa Pro", serif;
}
.carousel .mc.slide-main p strong {
  font-size: 1rem;
  font-weight: 500;
}
.carousel .mc.slide-main p a {
  margin-top: 0 !important;
  background: transparent !important;
  padding: 0 !important;
  font-size: 0.8rem;
  font-family: "Noto Serif", serif;
}
.carousel .mc.slide-main.is-selected .legende {
  bottom: 0;
}
.carousel .mc.slide-main.is-selected .legende h1 {
  bottom: 75%;
  transition: bottom 0.6s 1s;
}

.carousel .mc.slide-hawaii-ep5 .legende a {
  background: #2C4159;
}
.carousel .mc.slide-hawaii-ep5 .legende a:hover {
  background: #1b2837;
}

.carousel[data-slide-name=slide-hawaii-ep5] .flickity-button.previous .flickity-button-icon,
.carousel[data-slide-name=slide-hawaii-ep5] .flickity-button.next .flickity-button-icon {
  fill: #fff;
}
.carousel[data-slide-name=slide-hawaii-ep5] .timer {
  background-color: #c5ddeb;
}

.carousel[data-slide-name=slide-main] .timer {
  background-color: #d2d2d2;
}

.carousel[data-slide-name=slide-hawaii] .flickity-button.previous .flickity-button-icon {
  fill: #fff;
}
.carousel[data-slide-name=slide-shades-of-white] .timer {
  background-color: #607682;
}

.carousel .mc.slide-shades-of-white .legende a {
  background: #607682;
}
.carousel .mc.slide-shades-of-white .legende a:hover {
  background: #4a5b65;
}

@media (min-width: 576px) {
  #footer-access .menu > ul > li + li {
    margin-top: 0;
  }
  #access {
    padding: 10vh 10vw 20vh;
  }
  .mc.w-940-full {
    margin: 30px calc((100vw - ( 540px - 30px )) / 2 * -1) 0;
  }
  .jumbotron.slider .mc.w-940-full {
    margin: 0;
  }
  .carousel .mc .legende {
    width: 512px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
  }
  .mc.slide-main .legende {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .mc.w-700 {
    max-width: 700px;
    width: 700px;
  }
  .mc.w-940-full {
    margin: 30px calc((100vw - ( 720px - 30px )) / 2 * -1) 0;
  }
  .mc.w-940-full .mcf.w-940, .mc.w-940-full .rp-max-940 {
    max-width: none;
  }
  #access .menu > ul {
    font-size: 1rem;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
  }
  #access .menu > ul > li {
    flex: 1 1 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  #access .menu > ul > li + li {
    margin-top: 0;
  }
  #access .menu > ul > li > a {
    font-size: 1.25rem;
  }
  #access .menu > ul a {
    display: block;
    line-height: 1.3;
  }
  blockquote {
    border-left: none;
    padding-left: 0;
    padding-right: 60px;
    margin: 2rem 0;
  }
  blockquote.important-quote {
    font-size: 2.025rem;
  }
  blockquote.important-quote cite {
    font-size: 1.4rem;
  }
  blockquote p, blockquote cite {
    margin-left: auto;
  }
  .entries ol.row {
    margin-top: 64px;
  }
  .entries article > .row {
    align-items: center;
  }
  .entries a {
    transition: all 0.2s;
    transform: translate(0, 0);
  }
  .entries a:hover {
    transform: translate(8px, 0);
  }
  .entries a:hover .info {
    transform: translate(-1.6px, 0);
  }
  .entries .pict {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-left: -30px;
    margin-right: 30px;
    margin-top: 0 !important;
  }
  .entries .info {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-bottom: 15px;
    margin-top: -1.8rem;
  }
  .entries .footer-info {
    margin-top: 32px;
  }
  .entries .featured .pict {
    flex: 0 0 75%;
    max-width: 75%;
    margin-left: 16.6666666667%;
    margin-left: 60px;
    margin-bottom: 30px;
  }
  .entries .featured .info {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-left: 33.3333333333%;
    margin-top: 0;
    border-left: solid 1px #f1f4f5;
  }
}
@media (min-width: 768px) and (prefers-color-scheme: dark) {
  .entries .featured .info {
    border-left-color: #2d3d43;
  }
}
@media (min-width: 768px) {
  .entries li:nth-child(odd) .pict {
    order: 1;
    margin-left: 30px;
    margin-right: -30px;
  }
  .entries li:nth-child(odd) .info {
    text-align: right;
    padding-left: 15px;
    padding-right: 30px;
  }
  .entries li:nth-child(odd) .info h3,
  .entries li:nth-child(odd) .info .head {
    margin-left: auto;
  }
  .entries li:nth-child(odd) .footer-info:before {
    left: inherit;
    right: 0;
  }
  .entries li:nth-child(odd).featured .pict {
    order: 0;
    margin-left: 25%;
  }
  .entries li:nth-child(odd).featured .info {
    margin-left: 0;
    border-right: solid 1px #f1f4f5;
    border-left: none;
    margin-top: 0;
  }
}
@media (min-width: 768px) and (prefers-color-scheme: dark) {
  .entries li:nth-child(odd).featured .info {
    border-right-color: #2d3d43;
  }
}
@media (min-width: 768px) {
  .entries li + li {
    margin-top: 60px;
    padding-top: 0;
    border: none;
  }
}
@media (min-width: 768px) {
  .entries li:first-child.featured + li {
    margin-top: 60px;
    padding-top: 0;
  }
}
@media (min-width: 768px) {
  .entries.text-only ol.row {
    margin-top: 0;
  }
  .entries.text-only li {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 60px;
  }
  .entries.text-only .pict {
    display: none;
  }
  .entries.text-only .title {
    margin-left: 0 !important;
  }
  .entries.text-only .info {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left !important;
    padding-right: 60px;
  }
}
@media (min-width: 768px) {
  .entries .title {
    max-width: 384px;
  }
}
@media (min-width: 768px) {
  .entries#meta-nav article {
    width: 100%;
  }
  .entries#meta-nav li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .entries#meta-nav li .info {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .entries#meta-nav li:nth-child(even) .info {
    padding-left: 0;
    padding-right: 30px;
  }
  .entries#meta-nav li:nth-child(odd) .info {
    padding-right: 0;
    padding-left: 30px;
  }
  .entries#meta-nav li.parent,
  .entries#meta-nav li.previous {
    order: -1;
    display: flex;
  }
  .entries#meta-nav li.parent .info {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    padding-left: 15px;
  }
  .entries#meta-nav li.parent article {
    width: 100%;
    align-self: center;
  }
}
@media (min-width: 768px) {
  .jumbo {
    padding-bottom: 64px;
  }
}
@media (min-width: 768px) {
  .keep-reading .entries li + li {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
}
@media (min-width: 768px) {
  .photography-gallery .mc,
  .photography-gallery .mc.w-940 {
    margin-left: auto;
    margin-right: auto;
  }
}
body.wp-debug:after {
  content: "xs";
  display: block;
  font-weight: 700;
  padding: 0.4em 0.8em 0.2em;
  line-height: 1.4;
  background: #c00;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.24;
}
@media (min-width: 576px) {
  body.wp-debug:after {
    content: "sm";
  }
}
@media (min-width: 768px) {
  body.wp-debug:after {
    content: "md";
  }
}
@media (min-width: 992px) {
  body.wp-debug:after {
    content: "lg";
  }
}
@media (min-width: 1200px) {
  body.wp-debug:after {
    content: "xl";
  }
}