// *:focus {
//   outline: none;
// }

[aria-hidden="true"] a {
  pointer-events: none !important;
  display: none !important;
}
iframe {
  border: none;
}

.social-buttons {
  display: flex;
  margin-left: -.5em;

  &>* {
    flex: 0 0 auto;
  }
}

.btn-outline-primary {
  border-color: $light-gray;

  &:hover,
  &:focus {
    color: #fff;
    border-color: $color-primary;
  }
}

.u {
  text-decoration: underline;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: none;
  @include themed() {
    border-bottom: dotted 1px rgba( t( 'color-primary' ), .64 );
  }
}

@include dark() {
  .dark-brighten {
    filter: brightness( 1.6 );
  }
}

.grecaptcha-badge {
  position: absolute;
  left: -9999px;
}

.entry-content {

  p,
  nav:not(.entries) {
    a {
      text-decoration: underline;
      @include themed() {
        color: tt( $gray-700, $color-primary-300 );
      }
    }
    .button {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-outline-secondary;

      &.selected {
        @extend .btn-secondary;
        color: #fff;
      }
    }
    .button+br+.button {
      margin-top: .4em;
    }
  }

}

a.back-up {
  border: none !important;
}

.pager {
  text-align: center;
  margin-top: $space-8;
  margin-bottom: $space-8;

  &>div {
    padding: 0;
  }

  &.top {
    margin-top: 0;
  }

  .page-numbers {
    display: inline-block;
    padding: 4px 12px;
    border-radius: $border-radius;
    font-family: $headings-font-family;

    @include themed() {
      background: tt( $gray-200, $gray-800 );
      color: tt( $gray-700, $gray-300 );
      border-color: tt( $gray-200, $gray-800 );
    }
    min-width: 2.4em;
    text-decoration: none;
    transition: all $transition-duration;
    border: solid 1px $gray-200;

    &.dots {
      background: transparent;
    }

    &.current {
      background: transparent;
      @include themed() {
        border-color: $gray-800;
      }
      border: solid 1px $gray-200;
    }
  }
  a.page-numbers:hover {
    @include themed() {
      background: tt( $gray-300, $gray-700 );
      border-color: tt( $gray-300, $gray-700 );
    }
  }
}

html.nav-open {
  height: 100vh;
  overflow: hidden;

  .site-content-contain {
    position: relative;
    pointer-events: none;
    overflow: hidden;
  }
}

#sitemap-menu {

  &>ul {
    padding: 0;
  }

  ul ul {
    padding-left: $grid-gutter-width;
    // margin-bottom: 1em;
  }
  li {
    list-style: none;
    margin-top: .5em;

    &:before {
      content: '- ';
      opacity: .44;
    }

    &+li {
      // margin: 0;
    }
  }
  &>ul>li>a {
    @include themed() {
      color: t( 'body-color' );
    }
    font-weight: 700;
  }
  .sub-menu li {
    &:before {
      content: '– ';
    }
    a {
      font-weight: 700;
      @include themed() {
        color: t( 'color-secondary-600' );
      }
    }
  }
  .sub-menu .sub-menu li {
    &:before {
      content: '— ';
    }
    a {
      font-weight: 400;
      opacity: .84;
    }
  }
}

.menu-shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: -1;
  background: rgba( 0, 0, 0, .24 );
  visibility: hidden;
  opacity: 0;

  html.ready & {
    transition: visibility 0s linear $transition-duration, z-index 0s linear $transition-duration, opacity $transition-duration linear;
  }

  html.ready.nav-open & {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear, z-index 0s linear, opacity $transition-duration linear;
    z-index: 900;
  }
}

#opener {

  transition: all $transition-duration;

  &:before,
  &:after {
    transition: transform $transition-duration;
  }

  // &:focus {
  //   outline: none;
  // }

  html.nav-open & {
    &:before {
      transform: rotate( 45deg ) translate( 2px, 2px );
    }
    &:after {
      transform: rotate( -45deg ) translate( 3px, -3px );
    }
  }

  body.luminous-open & {
    opacity: 0;
  }
}

#access,
#footer-access {

  li {
    list-style: none;
  }
  a {
    @include themed() {
      color: t( 'body-color' );
    }
  }

  .menu>ul {
    padding: 0;
    font-family: $headings-font-family;

    &>li {
      &>a,
      &.same-level-subchildren .sub-menu li {
        line-height: 1.3;
        a {
          font-weight: 400;
          @include themed() {
            color: t( 'body-color' );
          }
        }
      }
      &+li {
        margin-top: $space-6;
      }
    }

    .sub-menu {
      font-size: $font-size-base;
      padding: 0;

      li {
        margin-top: $space-3;
        line-height: 1.2;
      }

      a {
        color: $color-secondary;
      }
      .sub-menu {
        @include sr-only();
      }
    }
  }

}
.skip-link:focus {
  @include themed() {
    background-color: t( 'body-bg' );
  }
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  display: block;
  height: auto;
  line-height: normal;
  padding: $space-2 $space-4;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  position: absolute;
  left: 5px;
}

#access {

  @include themed() {
    background: t( 'body-bg' );
  }

  max-height: 0;
  max-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;

  min-height: 100vh;
  padding: ( $grid-gutter-width * .9 ) $grid-gutter-width $space-8;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  overflow: scroll;

  html.js & {
    transition: transform $transition-duration * 2, opacity $transition-duration * 2, visibility 0s linear $transition-duration * 2;
  }

  html.nav-open & {
    -webkit-overflow-scrolling: touch;
    transform: translate( 0, 0 );
    opacity: 1;
    visibility: visible;
    transition: transform $transition-duration * 2, opacity $transition-duration * 2, visibility 0s;
  }

  .search-form {
    // height: calc( 100vh - #{$grid-gutter-width * 2} );
    margin: $space-8 math.div( -$grid-gutter-width, 2 ) 0;
    padding: 0 math.div( $grid-gutter-width, 2 );
    overflow: hidden;
  }

  #toc {
    font-family: $headings-font-family;
    margin-bottom: $space-8;

    .toc-head {
      font-size: $h4-font-size;
      font-weight: 500;
      line-height: 1.2;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .toc-action {
      font-size: $font-size-sm;
      font-style: italic;
    }
    nav {
      padding-left: math.div( $grid-gutter-width, 2 );
    }
    ol {
      padding: 0;
      margin: 0;
    }
    li {
      margin-top: math.div( $grid-gutter-width, 2 );
      line-height: 1.4;

      &:before {
        content: '– ';
        @include themed() {
          color: t( 'color-secondary-300' );
        }
      }
    }
  }

  .around-here {
    display: flex;
    flex-direction: column;

    .previous a,
    .next a {
      display: block;
      margin-bottom: $space-8;
      font-family: $headings-font-family;
      font-weight: 500;
      line-height: 1.2;
    }
    .sup-head {
      font-weight: 400;
      margin-bottom: 1.8em;
    }
  }

  a:hover {
    text-decoration: none;
  }
}

.search-form {
  margin-top: 0;
}

.breadcrumb a {
  transition: color $transition-duration;
}
.breadcrumb a:focus,
.breadcrumb a:hover {
  text-decoration: none;
  @include themed() {
    color: tt( #000, #fff );
  }
}

blockquote {
  border-left: solid 5px $color-primary-200;
  @include dark() {
    border-left-color: $color-primary-800;
  }
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  margin-left: -$grid-gutter-width;
  padding-top: .1em;
  padding-bottom: .1em;
  padding-left: $grid-gutter-width;
  font-family: $headings-font-family;
  font-size: $font-size-lg;
  @include themed() {
    color: tt( $gray-700, $color-primary-300 );
  }
  line-height: 1.4;
  font-weight: $headings-font-weight;

  &.important-quote {
    font-size: $font-size-lg * 1.2;
  }

  strong {
    font-weight: 500;
  }

  cite {
    display: block;
    margin-bottom: 1em;
    font-size: $font-size-md;
  }
}

hr {
  display: none;
}

.wp-smiley {
  margin-top: -.3em;
}

#branding {
  .hgroup {
    a {
      text-decoration: none;
    }
  }
}
a {
  word-wrap: break-word;
  // hyphens: auto;
}

.screen-reader-text {
  @include sr-only();
}

h1, h2, h3, h4, h5 {
  a {
    color: $headings-color;

    &:hover,
    &:focus {
      color: $headings-color;
      text-decoration: none;
    }
  }
}

h2 {
  position: relative;
}

//
//
//

.short-series {
  .mc + p,
  .mc + blockquote,
  p + .mc,
  blockquote + .mc {
    margin-top: 3.5rem;
  }
  .mc:not(.has-legend) + .mc {
    margin-top: 3.5rem;
    margin-top: 25vh;
  }

  .entry-content p {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.75rem;
  }
}

//
//
//

.search-form {

  position: relative;
  z-index: 998;

  &.static {
    z-index: initial;
  }

  label {
    font-family: $headings-font-family;
    font-weight: 300;
    margin-top: .4rem;
  }

  .search-field {
    width: 100%;
    width: calc( 100% + #{$grid-gutter-width} );
    border-top: none;
    border-left: none;
    border-right: none;
    margin: 0 math.div( -$grid-gutter-width, 2 );
    resize: none;

    padding: .5rem 1rem;
    line-height: 1.5;
    border-radius: 0;

    @include themed() {
      background: tt( inherit, transparent );
      color: tt( inherit, #fff );
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $color-primary;
    }
  }

  button {
    position: absolute;
    right: -.3rem;
    bottom: .5rem;
    width: $grid-gutter-width;
    height: $grid-gutter-width;
    -webkit-appearance: none;
    border: none;
    background: transparent;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   width: $grid-gutter-width;
    //   height: $grid-gutter-width;
    //   transform: translate( -50%, -50% );
    //   background: url( //cdn2.berengerzyla.com/assets/icons/magnifier.svg );
    //   background-position: center center;
    //   background-repeat: no-repeat;
    //   background-size: 14px;
    //   opacity: .4;
    // }
  }

}

.search-form-layover {
  background: $body-bg;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  transition: opacity .3s;

  z-index: 997;
  pointer-events: none;

  &.show {
    opacity: .94;
    pointer-events: all;
  }
}
.search-form-results {
  position: relative;
  z-index: 998;
  // overflow-y: scroll;
  // padding-bottom: $space-8;
  // height: 100%;

  .entries {
    ol {
      padding: $grid-gutter-width 0;
    }
    .info {
      padding-left: math.div( $grid-gutter-width, 2 );
    }
    .title {
      font-size: $font-size-base;
    }
    .content {
      font-size: $font-size-sm;
    }
  }
  .nothing-found {

  }
}

//
//
//

#reading-progress {
  position: sticky;
  top: 0;
  z-index: 999;
  text-align: right;
  margin: 0 math.div( -$grid-gutter-width, 2 );
  pointer-events: none;
}

#reading-progress-bar {
  width: 100%;
  height: 3px;
  position: relative;

  &:after {
    content: '';
    display: block;
    transition: transform $transition-duration;
    width: 100%;
    height: 100%;
    background: $color-primary;
    position: absolute;
    transform: scale( calc( var( --content-data-offset, 0 ) * 1 ), 1 );
    transform-origin: left;
    top: 0;
    left: 0;
  }
}

//
// Footer
//

footer#colophon {

  @include themed() {
    background: tt( 'color-secondary-100', map-get( map-get( $themes, dark ), 'body-bg' ) );
  }
  @include dark() {
    border-top: solid 1px $color-secondary-800;
  }
  margin-top: $space-8;
  padding: $space-8 math.div( $grid-gutter-width, 2 );
  font-size: $font-size-sm;

  .menu>ul {
    font-size: $font-size-sm;

    li {
      line-height: 1.2;
    }

    &>li>a,
    li.same-level-subchildren .sub-menu li a {
      font-weight: 400;
      @include themed() {
        color: t( 'color-secondary-900' );
      }
    }

    .sub-menu {
      font-size: $font-size-sm;
    }

  }

  .credits {

    font-family: $headings-font-family;
    margin-top: $grid-gutter-width;
    font-size: $font-size-xs;
    @include themed() {
      color: t( 'color-secondary-900' );
    }
    font-weight: 300;

    a {
      @include themed() {
        color: t( 'color-secondary-900' );
      }
    }

    strong {
      font-weight: 300;
    }
  }

}

//
// Content
//

.carousel,
.flickity-viewport {
  transition: height $transition-duration;
}

.mc {
  margin: ( $grid-gutter-width ) ( -$grid-gutter-width ) 0;
  // overflow: hidden;

  &:not(.has-legend) + .mc {
    margin-top: 2px;
  }

  &+.mc {
    margin-top: 0;
  }

  .legende {
    margin: math.div( $grid-gutter-width, 2 ) $grid-gutter-width $grid-gutter-width;
    font-size: $font-size-sm;
    line-height: 1.2;
    border-left: solid 1px $light-gray;
    @include dark() {
      border-left-color: $dark-gray;
    }
    padding: .4em ( $grid-gutter-width * .6 );
    @include themed() {
      color: tt( $dark-gray, $gray-500 );
    }

    p {
      margin: 0;

      & + p {
        margin-top: 1em;
      }
    }
  }

  & + p {
    margin-top: $space-6;
  }

  &.w-auto {
    width: auto !important;
  }

  &.clean {
    .photo {
      box-shadow: none !important;
    }
  }
}
.wp-block-embed + p {
  margin-top: $space-6;
}

.wp-block-embed {

  margin: ( $grid-gutter-width ) ( -$grid-gutter-width ) 0;

  .mc+& {
    margin-top: 0;
  }
  .mc:not(.has-legend)+& {
    margin-top: 2px;
  }

  .video {
    position: relative;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    @include dark() {
      box-shadow: 0 0 math.div( $grid-gutter-width, 4 ) rgba( 0, 0, 0, .24 );
    }
  }

}

@each $rp-max in map-get( $registered, max_width ) {
  .rp-max-#{$rp-max},
  .mcf.w-#{$rp-max} {
    max-width: #{$rp-max}px;
    flex-basis: #{$rp-max}px;
  }
}

.photo {

  display: flex;

  &:not(.float),
  &.float .pictbox {
    @include dark() {
      box-shadow: 0 0 math.div( $grid-gutter-width, 4 ) rgba( 0, 0, 0, .24 );
    }
  }

  .pictbox {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }

  &.float {

    .pictbox {
      position: relative;
      margin-right: 2px;
      overflow: hidden;

      &:last-child {
        margin-right: 0;
      }

    }
    .img {
      overflow: visible;

      img {
        max-width: 101%;
      }
    }
  }

  &.center {
    margin-left: $grid-gutter-width;
    margin-right: $grid-gutter-width;
  }

}

.mc.float {
  .photos {
    display: flex;
    // margin-left: -1px;
    // margin-right: -1px;
  }
  // .pictbox {
  //   overflow: hidden;
  // }
  .mcf {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    margin-left: 1px;
    margin-right: 1px;
    // overflow: hidden;
    border-radius: 1px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .img {
    overflow: visible;
    border-radius: 0;

    img {
      max-width: 101%;
    }
  }
}

.img {
  overflow: hidden;
  border-radius: 1px;
  @include themed() {
    background-color: tt( darken( $body-bg, 2% ), darken( map-get( map-get( $themes, 'dark' ), 'body-bg' ), 2% ) );
  }

  img {
    transition: filter .3s ease-in-out, transform .4s ease-in-out, opacity .3s ease-in-out;
    filter: blur( 0px ) contrast( 1 ) grayscale( 0 ) brightness( 1 );
    transform: scale( 1, 1 );
  }
  img.lazy {
    filter: blur( 20px ) contrast( .8 ) grayscale( .1 ) brightness( 1.2 );
    transform: scale( 1.1, 1.1 );
    opacity: 1;
    z-index: 1;
    color: transparent;

    &.done {
      opacity: 0;
    }
  }

  &.dl img {
    height: 100%;
  }
  &.dp img {
    width: 100%;
  }
}

.entries {

  .featured {

    @include media-breakpoint-down( sm ) {
      .pict {
        margin-top: 0 !important;
        margin-bottom: 1.8em;
      }
    }

    .pict {
      // @include make-col( 8 );
      @include make-col( 12 );
      // @include make-col-offset( 3 );
    }
    .info {
      @include make-col( 10 );
    }

  }

  .pict {
    padding: 0;
    margin-top: .2em;
  }

  h2,
  .h2 {
    margin-top: $space-8;
    padding-top: 0;
    margin-bottom: 0;
  }
  .h2sub {
    margin: 0;
    max-width: none;
    font-size: $font-size-sm;
    color: $color-secondary;
  }

  ul, ol {
    margin-top: $space-6;
    padding: 0;
    list-style: none;
  }
  li {
    @include make-col( 12 );
  }
  li + li {
    margin-top: $grid-gutter-width;
    @include themed() {
      border-top: solid 1px tt( $gray-200, $gray-800 );
    }
    padding-top: $grid-gutter-width;
  }
  article.has-sup-head .pict {
    margin-top: 1.8rem;

    .entry-footer & {
      margin-top: 0;
    }
  }

  article > .row {
    align-items: flex-start;
  }
  a {
    // width: 100%;
    flex: 1 1 auto;
    @include themed() {
      color: t( 'body-color' );
    }
    &:hover {
      text-decoration: none;
    }
  }

  .mc {
    margin: 0;
    position: relative;
    width: auto !important;

    &,
    img {
      border-radius: 1px;
    }

    &:after {
      pointer-events: none;
      content: '';
      display: block;
      position: absolute;
      border-radius: 1px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 1px rgba( 0, 0, 0, .2 );
    }
  }

  .no-img .info {
    padding-left: math.div( $grid-gutter-width, 2 );
  }

  .pict {
    @include make-col( 3 );
  }
  .info {
    @include make-col( 9 );
    padding-left: $grid-gutter-width;
    transform: translate( 0, 0 );
    transition: all $transition-duration;
  }

  .footer-info {
    margin-top: 1em;
    padding-top: 1em;
    position: relative;

    &>span {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: $space-8;
      border-top: dotted 1px $gray-200;
      @include dark {
        border-top-color: $gray-800;
      }
    }
    font-size: $font-size-xs;
    @include themed() {
      color: t( 'color-secondary' );
    }
    line-height: 1.4;
    // font-family: $headings-font-family;
  }

  .title {
    margin: 0;
    padding: 0;
    font-size: $h5-font-size;
    font-weight: 500;
    @include media-breakpoint-down( xs ) {
      hyphens: auto;
    }
  }

  .content {
    line-height: 1.4;
    @include themed() {
      color: t( 'color-secondary-600' );
    }
    max-width: $space-14;

    p:last-child {
      margin-bottom: 0;
    }
  }

  li.coming-soon article {
    filter: brightness( .84 ) contrast( .84 );
    opacity: .64;
  }

  // &>header+div {
  //   margin-top: $space-6;
  // }

}

.sup-head {
  font-size: $font-size-xs;
  text-transform: uppercase;

  @include themed() {
    color: t('color-secondary');
  }
  letter-spacing: .1em;
  display: block;
  font-family: $headings-font-family;
  line-height: 1.4;
  margin-left: .1em;
}
.sup-head {
  margin-bottom: .6em;
}

.map {
  margin-top: $grid-gutter-width * 2;
  margin-bottom: $grid-gutter-width * 2;
}

//
// Tooltips
//

.tooltip {

  position: absolute;
  top: -999px;
  left: 0;

  z-index: 9999;

  max-width: $space-12;
  max-width: calc( 100vw - #{math.div( $grid-gutter-width, 2 )} );
  margin-top: 0;
  opacity: 0;
  visibility: hidden;

  transition: visibility 0s linear ( $transition-duration * 2 ), opacity $transition-duration linear 0s, margin-top $transition-duration linear 0s;
  pointer-events: none;


  &.small {
    .inner {
      padding: ( $grid-gutter-width * .2 ) ( $grid-gutter-width * .3 );
      border-radius: $border-radius;
      font-size: $font-size-xs;
    }
  }

  .inner {

    font-family: $headings-font-family;
    font-size: $font-size-sm;
    line-height: $font-size-base;

    padding: ( $grid-gutter-width * .4 ) ( $grid-gutter-width * .5 );
    background: $tooltip-bg;
    border-radius: $border-radius-lg;
    color: $tooltip-color;
    text-align: center;

    box-shadow: 0 2px 4px rgba( 0, 0, 0, .4 );

  }

  &.reference,
  &.copyright {

    max-width: $space-12;
    max-width: calc( 100vw - #{math.div( $grid-gutter-width, 2 )} );
    pointer-events: all;
    z-index: 900;

    .inner {
      background: $tooltip-ref-bg;
      color: $tooltip-ref-color;
      padding: ( $grid-gutter-width * .2 ) ( $grid-gutter-width * .5 );

      p {
        margin: .5em 0;
      }
    }

    strong {
      color: $tooltip-ref-link-color;
    }
    a {
      color: $tooltip-ref-link-color;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .arrow:after {
      border-color: transparent transparent $tooltip-ref-bg;
    }

  }

  &.copyright.show {
    animation: flash .4s;
  }

  @keyframes flash {
    0% {
      opacity: 0;
    }
    20% {
      opacity: .9;
    }
    40% {
      opacity: .4;
    }
    100% {
      opacity: 1;
    }
  }

  .arrow {
    position: absolute;
    &:after {
      display: block;
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 4px 4px 4px;
      border-color: transparent transparent $tooltip-bg;
    }
  }

  &[data-popper-placement="bottom"] {
    padding-top: 6px;
    margin-top: 2px;

    .arrow {
      top: 2px;
    }
  }
  &[data-popper-placement="top"] {
    padding-bottom: 6px;
    margin-top: -2px;

    .arrow {
      bottom: 2px;
      &:after {
        transform: rotate( 180deg );
      }
    }
  }
  // &[data-popper-placement="left"] {

  // }
  // &[data-popper-placement="right"] {

  // }

  &.show {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity $transition-duration linear 0s;
  }

}

.entries + .row {
  margin-top: $space-8;
  font-size: $font-size-sm;
}

.tooltip.copyright {
  // max-width: none;
  // padding: $space-4;
  pointer-events: all;
  font-size: $font-size-sm;
}

#credit-references {
  font-size: $font-size-sm;

  ol,
  ul {
    max-width: none;
    padding-left: 0;
    counter-reset: li;
  }

  li {
    counter-increment: li;

    &:before {
      content: "#"counter(li);
      color: $color-secondary;
    }
    display: flex;
    align-items: flex-start;
  }

  p {
    margin-top: 0;
  }

  .reference-content {
    flex: 1 1 auto;
    min-width: 0;
  }
}

.reference-link {
  &:before {
    content: "\00a0";
  }
}

//
// Rating
//

.rating-sharing .h2 {
  margin-bottom: 0;
}
#rating-mod {

  .star {
    font-size: $font-size-xl;
    cursor: pointer;
    line-height: .9;
    display: inline-block;
    color: $color-primary-200;
    height: 1em;

    &.glow {
      opacity: 1;
      color: $color-primary;
    }
  }

  .rating:hover .star {
    color: $color-secondary;

    &.glow {
      color: $color-secondary-800;
    }
  }
}

//
// Social
//

.social-button {
  display: block;
  margin: 0 .5em;
}

.social-action {
  display: inline-block;
  padding: .6em .8em;
  color: #fff;
  // font-family: $font-family-sans-serif;
  font-size: $font-size-xs;
  line-height: 1;
  vertical-align: middle;
  border-radius: $border-radius;
  box-shadow: inset 0 0 0 rgba( 0, 0, 0, 0 );

  transition: box-shadow $transition-duration;

  &:hover,
  &:focus {
    color: #fff;
    box-shadow: inset 0 -2px 0 rgba( 0, 0, 0, .24 );
    text-decoration: none;
  }
}

.social-action.facebook {
  background: $color-facebook;
}
.social-action.twitter {
  background: $color-twitter;
}
.social-action.pinterest {
  background: $color-pinterest;
}

.social-button .count {
  display: none;
}

//
// Keep Reading
//

.keep-reading {
  .h2 {
    margin-bottom: 0;
  }
}

//
// Forms
//

.form-control:not([type="search"]) {
  border: none;
  box-shadow: 0 -1px 2px rgba( 0, 0, 0, 0.06 ), 0 1px 1px rgba( 0, 0, 0, 0.12 );
  font-size: $font-size-base;
  font-family: $headings-font-family;
  padding: .75rem 1rem;
  @include themed() {
    color: tt( #000, #fff );
    background-color: tt( #fff, mix( $color-secondary-900, #000 ) );
  }
  transition: background-position 0.5s, box-shadow 0.5s, border-bottom 0.1s;
  background-position: center right -1.6225rem;
}
textarea.form-control {
  padding: .75rem 1rem;
}

input[type="submit"]:not(.search-submit),
button[type="submit"]:not(.search-submit) {

  cursor: pointer;

  text-transform: uppercase;
  font-family: $headings-font-family;
  font-size: $font-size-sm;
  line-height: 1;

  @include themed() {
    // background: t( 'color-secondary' );
    background: $color-secondary;
  }
  border: solid 2px $color-secondary;

  width: 100%;
  height: 50px;

  border-radius: $border-radius;
  box-shadow: 0 -1px 2px rgba( 0, 0, 0, 0.06 ), 0 1px 1px rgba( 0, 0, 0, 0.12 );

  color: #fff;

  font-weight: 700;

  &:hover,
  &.selected,
  &:focus,
  &:active:focus {
    background: darken( $color-secondary, 10% );
    border-color: darken( $color-secondary, 10% );
    outline: none;
  }

  transition: background-color .3s .1s, color .3s .1s, width .3s .1s, height .3s .1s, border-width .3s .1s, border-color .3s .1s, box-shadow .3s .1s, padding .3s .1s, border-radius .3s .1s;

  span {
    transition: opacity .3s .1s;
    white-space: nowrap;
  }

}

.progress-button {
  position: relative;
  display: inline-block;
  text-align: center;
  min-width: 100%;
}
.progress-button svg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX( -50% );
  pointer-events: none;
  transform-origin: 50% 0 0px;
}
.progress-button svg path {
  opacity: 0;
  fill: none;
}

.progress-button svg.progress-circle path {
  stroke: $color-primary;
  stroke-width: 2;
  transition: opacity .1s, stroke-dashoffset .3s;
}
.loading.progress-button svg.progress-circle path {
  transition: opacity .1s .3s, stroke-dashoffset .1s .3s;
}

.loading.progress-button svg.progress-circle {
  transform-origin: 50% 50% 0px;
  top: 0;
  animation: spin .8s infinite linear;

  @keyframes spin {
    0% {
      transform: translateX( -50% ) rotate( 0deg );
    }
    100% {
      transform: translateX( -50% ) rotate( 360deg );
    };
  }
}

.progress-button svg.checkmark path,
.progress-button svg.cross path {
  @include themed() {
    stroke: t( 'body-bg' );
  }
  stroke-linecap: round;
  stroke-width: 2;
  transition: opacity .1s, stroke-dashoffset .3s;
}
.progress-button svg.checkmark path {
  stroke: #fff;
}

/* Loading, success and error effects */
.loading.progress-button button {
  width: 50px !important; /* make a circle */
  height: 50px !important; /* make a circle */
  border-width: 2px;
  border-color: #ddd !important;
  background-color: transparent !important;
  color: #fff;
  box-shadow: none;

  transition: background-color .3s, color .3s, width .3s, height .3s, border-width .3s, border-color .3s, box-shadow .3s, padding .3s, border-radius .3s;
}

.loading.progress-button span {
  transition: opacity .15s;
}

.loading.progress-button span,
.success.progress-button span,
.error.progress-button span {
  opacity: 0; /* keep it hidden in all states */
}

.success.progress-button button {
  border-color: $green !important;
  background-color: $green !important;
  cursor: initial;
}

.error.progress-button button {
  border-color: $red;
  background-color: $red;

  &:hover,
  &.selected,
  &:focus,
  &:active:focus {
    background: darken( $red, 10% );
    border-color: darken( $red, 10% );
  }
}

.loading.progress-button button {
  border-radius: 25px;
}
.loading.progress-button svg.progress-circle path,
.success.progress-button svg.checkmark path,
.error.progress-button svg.cross path {
  opacity: 1;
}

.form-message {
  height: 0;
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 0;
  max-width: none !important;

  &.visible {
    height: auto;
    padding: 1em 1.8em;
    opacity: 1;
    margin-top: 1em;
    margin-bottom: 1em;
    line-height: inherit;
    transition: opacity .4s .4s, padding .4s, margin .4s, line-height .4s;
  }

  &.success {
    background: $green;
    @include themed() {
      color: t( 'body-bg' );
    }
  }

  transition: opacity .2s, padding .2s .2s, margin .2s .2s, line-height .2s .2s;
  background: $red;
  color: $white;
  font-size: $font-size-sm;
  margin-top: 1em;
  padding: 0 1em;
  overflow: hidden;
  border-radius: $border-radius;
  box-shadow: 0 -1px 2px rgba( 0, 0, 0, 0.06 ), 0 1px 1px rgba( 0, 0, 0, 0.12 );
}

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
  background-position: center right .6225rem;
  background-repeat: no-repeat;
  background-size: 1.125rem 1.125rem;
  box-shadow: 0 -1px 2px rgba( #f0ad4e, .36 ), 0 1px 1px rgba( #f0ad4e, .72 ) !important;
}
.d1 .form-control {
  transition-delay: .1s;
}
.d2 .form-control {
  transition-delay: .2s;
}
.d3 .form-control {
  transition-delay: .3s;
}

//
// Comments
//

#reactions-comments {
  @include themed() {
    background: tt( rgba( $color-secondary-100, .64 ), rgba( mix( map-get( map-get( $themes, 'dark' ), 'body-bg' ), $color-secondary-900, 60% ), .64 ) );
  }
  @include dark() {
    border-top: solid 1px $color-secondary-800;
  }
  margin-top: 3em;
  margin-left: math.div( -$grid-gutter-width, 2 );
  margin-right: math.div( -$grid-gutter-width, 2 );

  padding-left: math.div( $grid-gutter-width, 2 );
  padding-right: math.div( $grid-gutter-width, 2 );
  padding-bottom: $space-8;

  margin-bottom: -$space-8;

  #reply-title {
    display: none;
  }
}

#comments .h2 {
  margin-bottom: 1em;
  padding-top: 0;
}
#respond {
  .h2 {
    padding-top: 1em;
    margin-bottom: 1em;
  }
  .comment-intro {
    margin-bottom: 2em;
  }
}

form {
  margin-top: 2em;

  .form-group,
  .form-message {
    margin-left: math.div( -$grid-gutter-width, 2 );
    margin-right: math.div( -$grid-gutter-width, 2 );
  }
}

.comment-list {

  font-size: $font-size-sm;
  padding: 0;

  li {
    list-style: none;

    &+li {
      margin-top: $grid-gutter-width;
    }
  }

  a {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: top;
  }

  .comment-author {
    text-transform: uppercase;
    font-weight: 700;
  }

  // .date-time {
  //   color: $color-secondary;
  // }

  .comment-content {

    padding-left: math.div( $grid-gutter-width, 2 );

    p {
      max-width: $max-width-text;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .comment-meta {
    padding-left: math.div( $grid-gutter-width, 2 );
    margin-top: 1em;

    a {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-outline-primary;
      font-size: $font-size-xs !important;
      @include themed() {
        color: tt( $dark-gray, $light-gray );
      }
    }
  }

}

input,
textarea {
  font-family: $font-family-base;
}

.absorb-margin-bottom {
  margin-bottom: -$space-8;
}

.jumbo {
  margin-top: $grid-gutter-width;
  margin-left: math.div( -$grid-gutter-width, 2 );
  margin-right: math.div( -$grid-gutter-width, 2 );
  padding: ( $grid-gutter-width ) math.div( $grid-gutter-width, 2 );

  @include themed() {
    background-color: t( 'color-secondary-100' );
  }
}

.medium-zoom-overlay {
  z-index: 100;

  @include themed() {
    background: t( 'body-bg' ) !important;
  }
  // transition: opacity 1s !important;
}
.medium-zoom-image--opened {
  z-index: 101;
}
// .medium-zoom-image {
//   transition: none !important;
// }

.photography-gallery {

  .mc {
    height: 100vh;
    padding: math.div( $grid-gutter-width, 2 ) 0;
    position: relative;
    display: flex;
    flex-direction: column;

    &:not(.has-legend)+.mc {
      margin-top: 0;
    }

    .photo {
      height: 100%;
      box-shadow: none;
    }
    .pictbox {
      height: 100%;
      margin-left: math.div( -$grid-gutter-width, 2 );
      margin-right: math.div( -$grid-gutter-width, 2 );
    }
    .img.rp {
      padding: 0 math.div( $grid-gutter-width, 2 );
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img img {
      // object-fit: contain;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: initial;
      @include dark() {
        box-shadow: 0 0 math.div( $grid-gutter-width, 4 ) rgba( 0, 0, 0, .24 );
      }
    }
  }

  .img {
    background: transparent;
  }
  .img .lazy {
    position: absolute !important;
    left: auto;
    right: auto;

    &.fw {
      height: 100%;
      width: auto;
    }
    &.fh {
      width: 100%;
      height: auto;
    }
  }

  .legende {
    border: none;
    margin: 0;
    text-align: right;
    margin-left: auto;
    font-size: $font-size-sm;
  }

}

.flickity-button {
  background: transparent;
  width: 24px;
  height: 24px;
  transition: width ( $transition-duration * 2 );

  &.previous {
    left: 0;
  }
  &.next {
    right: 0;
  }

  &:hover {
    width: 34px;
    background: transparent;
  }
}
.flickity-button-icon {
  transition: fill ( $transition-duration * 2 );
}

.jumbotron.slider {
  margin-left: math.div( -$grid-gutter-width, 2 );
  margin-right: math.div( -$grid-gutter-width, 2 );
  overflow: hidden;
}

.carousel {

  .timer {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    transition: opacity .5s, background-color .5s;
    background-color: #000;
    opacity: 0;
    border-radius: 0 1px 1px 0;

    &.start {
      animation: carouselTimer 10s linear 0s 1 normal forwards;
      opacity: 1;
    }
    @keyframes carouselTimer {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      };
    }
  }
  &:hover .timer {
    animation-play-state: paused;
    opacity: .5;
  }

  .flickity-viewport {
    .img img {
      transition: none;
    }
  }

  .mc.is-selected {
    .legende {
      bottom: $space-4;
      opacity: 1;
      transition: opacity .8s 1s, bottom .6s 1s;
    }
  }
  .mc .legende {
    position: absolute;
    bottom: -$space-4;
    right: $space-4;
    left: $space-4;
    margin: 0;
    border: none;
    line-height: 1.4;
    opacity: 0;

    @include themed() {
      background: tt( #fff, $gray-900 );
    }
    border-radius: 1px;
    padding: $grid-gutter-width;
    text-align: center;

    transition: opacity .4s 0s, bottom 0s .4s;
    will-change: opacity, bottom;

    h1, h2 {
      margin: 0 0 .5em;
      padding-top: 0;
      @include themed() {
        color: t( $body-color );
      }
    }

    a {
      font-family: $headings-font-family;
      padding: $space-2;
      background: $color-primary;
      display: block;
      color: #fff;
      border-radius: 1px;
      margin-top: $space-4;
      text-decoration: none;
      font-weight: 500;
      transition: all ( $transition-duration );

      &:hover {
        background: $color-primary-500;
      }
    }

    p {
      margin-left: auto;
      margin-right: auto;
    }

  }

}

.carousel .mc.slide-main {
  .legende {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #fff;

    font-size: $font-size-md;
    font-weight: 700;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
  }
  h1 {
    font-size: 0;
    background: url( '/cdn/assets/7.x/images/logo-full-black-en.svg' ) no-repeat center;
    body.lang-fr & {
      background: url( '/cdn/assets/7.x/images/logo-full-black-fr.svg' ) no-repeat center;
    }
    width: 60vw;
    height: 50px;
    position: absolute;
    bottom: 70%;

    transition: bottom 0s .4s;
  }
  p {
    position: absolute;
    bottom: $grid-gutter-width;
    // right: $grid-gutter-width;
    right: $space-5;
    text-align: right;
    font-family: $headings-font-family;

    strong {
      font-size: $font-size-base;
      font-weight: 500;
    }

    a {
      margin-top: 0 !important;
      background: transparent !important;
      padding: 0 !important;
      font-size: $font-size-xs;
      font-family: $font-family-base;
    }
  }

  &.is-selected .legende {
    bottom: 0;

    h1 {
      bottom: 75%;
      transition: bottom .6s 1s;
    }
  }
}
.carousel .mc.slide-hawaii-ep5 {
  .legende a {
    background: #2C4159;
    &:hover {
      background: darken( #2C4159, 10% );
    }
  }
}
.carousel[data-slide-name="slide-hawaii-ep5"] {
  .flickity-button.previous .flickity-button-icon,
  .flickity-button.next .flickity-button-icon {
    fill: #fff;
  }
  .timer {
    background-color: #c5ddeb;
  }
}
// .mc.slide-hawaii {
// }
.carousel[data-slide-name="slide-main"] {
  .timer {
    background-color: #d2d2d2;
  }
}
.carousel[data-slide-name="slide-hawaii"] {
  .flickity-button.previous .flickity-button-icon {
    fill: #fff;
  }
  .timer {
    background-color: $headings-color;
  }
}
.carousel[data-slide-name="slide-shades-of-white"] {
  .timer {
    background-color: #607682;
  }
}

.carousel .mc.slide-shades-of-white {
  .legende a {
    background: #607682;
    &:hover {
      background: darken( #607682, 10% );
    }
  }
}
