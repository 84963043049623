/**
 * For calculations
 */

$menu-bar-height: 76px;

/**
 * Colors
 */

$color-primary-100: #e5ece9;
$color-primary-200: #c8d7d0;
$color-primary-300: #aac2b7;
$color-primary-400: #83a595;
$color-primary-500: #608473;
$color-primary-600: #4a6759;
$color-primary-700: #354940;
$color-primary-800: #27352f;
$color-primary-900: #18221d;

$color-secondary-100: #f1f4f5;
$color-secondary-200: #d2dde1;
$color-secondary-300: #b4c5cc;
$color-secondary-400: #8ba6b1;
$color-secondary-500: #648694;
$color-secondary-600: #4f6b76;
$color-secondary-700: #3b4f57;
$color-secondary-800: #2d3d43;
$color-secondary-900: #1f2a2f;

$theme-colors: (
  // primary: #8abe51,
  // primary: #a3cf4d,
  // primary: #9bb4c1,
  primary: $color-primary-400,
  secondary: $color-secondary-400,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);

$green: #8fcd7e;

$color-primary: map-get( $theme-colors, primary );
$color-secondary: map-get( $theme-colors, secondary );

$light-gray: $gray-200;
$dark-gray: $gray-800;

$link-color: theme-color( 'primary' );
$link-decoration: none;
$link-hover-color: darken( $link-color, 15% );

$color-facebook: #4666b8;
$color-googleplus: #dd4b39;
$color-pinterest: #e32522;
$color-twitter: #12b0ff;

// $img-placeholder-color: #e7e9e4;

// $tooltip-font-size:                 $font-size-sm !default;
// $tooltip-max-width:                 200px !default;
// $tooltip-color:                     $white !default;
// $tooltip-bg:                        $black !default;
// $tooltip-border-radius:             $border-radius !default;
// $tooltip-opacity:                   .9 !default;
// $tooltip-padding-y:                 .25rem !default;
// $tooltip-padding-x:                 .5rem !default;
// $tooltip-margin:                    0 !default;

$tooltip-ref-color: $tooltip-color;
$tooltip-ref-bg: $dark-gray;
$tooltip-ref-link-color: mix( $tooltip-ref-color, $link-color );

$themes: (
  default: (
    body-color: $body-color,
    body-bg: #fff,
    color-primary: $color-primary,
    color-secondary: $color-secondary,
    color-secondary-100: $color-secondary-100,
    color-secondary-300: $color-secondary-300,
    color-secondary-600: $color-secondary-600,
    color-secondary-900: $color-secondary-900,
  ),
  dark: (
    body-color: #fff,
    body-bg: #1c1f25, //#1d2025, //$color-secondary-900, // #2c3137 // #1c1f25
    // body-bg: mix( #2c3137, #1c1f25 ), //#1d2025, //$color-secondary-900, // #2c3137 // #1c1f25
    color-primary: $color-primary-300,
    color-secondary: $color-secondary-300,
    color-secondary-100: $color-secondary-900,
    color-secondary-300: $color-secondary-600,
    color-secondary-600: $color-secondary-300,
    color-secondary-900: $color-secondary-100,
  ),
);

/**
 * Grid
 */

$grid-gutter-width: 30px;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 720px,
  xl: 720px
);

/**
 * Types
 */

// $font-size-base:              1.125rem;
$font-family-sans-serif:      'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif:           'Noto Serif', serif;
$font-family-base:            $font-family-serif;
$headings-font-family:        'Tisa Pro', serif;
$headings-font-weight:        300;
$font-fontello:               'Fontello';

$font-size-xl:                ( $font-size-base * 2 );
$font-size-lg:                ( $font-size-base * 1.25 );
$font-size-md:                ( $font-size-base * .9 );
$font-size-sm:                ( $font-size-base * .875 );
$font-size-xs:                ( $font-size-base * .8 );

// $line-height-base:            1.58;
$line-height-base:            1.7;

$h1-font-size:                $font-size-base * 2.025;
$h2-font-size:                $font-size-base * 1.6875;
$h3-font-size:                $font-size-base * 1.4;

$h1-font-size-lg:             $h1-font-size + .25rem;
$h2-font-size-lg:             $h2-font-size + .5rem;
$h3-font-size-lg:             $h3-font-size + .25rem;
$h4-font-size-lg:             $h4-font-size;
$h5-font-size-lg:             $h5-font-size;
$h6-font-size-lg:             $h6-font-size;

/**
 * Spacing
 */

// $space-jumbo:                 ( $grid-gutter-width / $font-size-base * 1rem ) * 8;
// $space-xl:                    ( $grid-gutter-width / $font-size-base * 1rem ) * 6;
// $space-lg:                    ( $grid-gutter-width / $font-size-base * 1rem ) * 4;
// $space-md:                    ( $grid-gutter-width / $font-size-base * 1rem ) * 2;
// $space-sm:                    ( $grid-gutter-width / $font-size-base * 1rem );
// $space-xs:                    ( $grid-gutter-width / $font-size-base * 1rem ) * .5;

$space-1: 16px * .25;
$space-2: 16px * .5;
$space-3: 16px * .75;
$space-4: 16px * 1;
$space-5: 16px * 1.5;
$space-6: 16px * 2;
$space-7: 16px * 3;
$space-8: 16px * 4;
$space-9: 16px * 6;
$space-10: 16px * 8;
$space-11: 16px * 12;
$space-12: 16px * 16;
$space-13: 16px * 24;
$space-14: 16px * 32;
$space-15: 16px * 40;
$space-16: 16px * 48;

$max-width-text:              map-get( $container-max-widths, md ) * math.div( 9, 12 );
$max-width-title:             map-get( $container-max-widths, lg ) * math.div( 9, 12 ); // 10 columns of LG

/**
 * Timing
 */

$transition-duration: .2s;

//
// RP RATIOS
//

// $rp-ratios: (

//   56: ( 2252 / 1267 ), // 16:9 portrait
//   67: ( 3 / 2 ), // 2:3 portrait
//   75: ( 4 / 3 ), // 4:3 portrait
//   100: 1, // square
//   133: ( 3 / 4 ), // 4:3 landscape
//   150: ( 2 / 3 ), // 2:3 landscape
//   178: ( 1267 / 2252 ), // 16:9 landscape

//   300: ( 1 / 3 ), // pano picture
//   400: ( 1 / 4 ), // pano picture
//   25: ( 4 / 1 ), // pano picture
//   33: ( 3 / 1 ), // pano picture

//   // Cameroon
//   160:	( 1408 / 2252 ), // Ep2 Mt Manengouba
//   190:	( 1185 / 2252 ), // Ep2 Mt Manengouba
//   211:	( 332 / 700 ), // Ep5 Mt Cameroon Map

//   // NYC
//   140: ( 997 / 1400 ),

//   // Heading South
//   235: ( 958 / 2252 ), // drawings
//   93: ( 2252 / 2102 ), // profile pictures
//   127: ( 1778 / 2252 ), // profile pictures
//   193: ( 1167 / 2252 ), // profile pictures

//   //
//   82: ( 1000 / 820 ), // 7.x header pictures

//   // Press&Pubs
//   77: ( 1499 / 1154 ),

// );

// $rp-max-widths: (

//   700,

//   285,
//   641,

//   // 700px - 2 equal pictures
//   349,
//   // 403,
//   // 134,

//   // 940x - 4 equal pictures
//   233,
//   // 940px - 3 equal pictures
//   312,
//   // 940px - 2 equal pictures
//   469,

//   // 940px - 650 + 288
//   650, 288,
//   // 940px - 682 + 256
//   682, 256,

//   // 700px - 215 + 483,
//   215, 483,

//   // NYC #1 vertical panos
//   139,
//   418,

//   // Press&Pubs
//   174,

//   // 940px - 617 + 309
//   // 617,
//   // 309,

//   // 700px - half
//   // 350,

// );
