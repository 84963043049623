@use "sass:math";

@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
$registered: (ratio: (25: 4, 33: 3, 44: 2.254196643, 56: 1.777426993, 67: 1.5, 75: 1.333333333, 77: 1.298960139, 82: 1.219512195, 93: 1.071360609, 100: 1, 122: 0.82, 127: 0.789520426, 133: 0.75, 140: 0.712142857, 150: 0.666666667, 160: 0.625222025, 178: 0.562611012, 190: 0.526198934, 193: 0.518206039, 211: 0.474285714, 225: 0.44354403, 235: 0.425399645, 300: 0.333333333, 400: 0.25), max_width: (940, 700, 720, 350, 233, 235, 313, 470, 544, 485, 215, 651, 289, 684, 256, 215, 483, 140, 420, 938, 288, 650, 349));
;
@import '00-variables';
@import '01-mixins';

@import 'bootstrap/buttons';
@import 'bootstrap/forms';

@import '../../../../../../../node_modules/flickity/css/flickity.css';

@font-face {
	font-family: 'Tisa Pro';
	src: url( '/cdn/assets/fonts/tisa-pro/TisaPro-Ita.woff2' ) format( 'woff2' ),
			 url( '/cdn/assets/fonts/tisa-pro/TisaPro-Ita.woff' ) format( 'woff' );
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Tisa Pro';
	src: url( '/cdn/assets/fonts/tisa-pro/TisaPro-Medi.woff2' ) format( 'woff2' ),
			 url( '/cdn/assets/fonts/tisa-pro/TisaPro-Medi.woff' ) format( 'woff' );
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Tisa Pro';
	src: url( '/cdn/assets/fonts/tisa-pro/TisaPro-MediIta.woff2' ) format( 'woff2' ),
			 url( '/cdn/assets/fonts/tisa-pro/TisaPro-MediIta.woff' ) format( 'woff' );
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Tisa Pro';
	src: url( '/cdn/assets/fonts/tisa-pro/TisaPro-Bold.woff2' ) format( 'woff2' ),
			 url( '/cdn/assets/fonts/tisa-pro/TisaPro-Bold.woff' ) format( 'woff' );
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Tisa Pro';
	src: url( '/cdn/assets/fonts/tisa-pro/TisaPro-BoldIta.woff2' ) format( 'woff2' ),
			 url( '/cdn/assets/fonts/tisa-pro/TisaPro-BoldIta.woff' ) format( 'woff' );
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Tisa Pro';
	src: url( '/cdn/assets/fonts/tisa-pro/TisaPro.woff2' ) format( 'woff2' ),
			 url( '/cdn/assets/fonts/tisa-pro/TisaPro.woff' ) format( 'woff' );
	font-weight: 400;
	font-style: normal;
}

@import '10-xs-full';
@media ( min-width: map-get( $grid-breakpoints, sm ) ) {
  @import '11-sm-full';
}
@media ( min-width: map-get( $grid-breakpoints, md ) ) {
  @import '12-md-full';
}

// @import '../_js/ui/node_modules/flickity/css/flickity';

body.wp-debug:after {

	content: 'xs';
	display: block;
	font-weight: 700;
	padding: .4em .8em .2em;
	line-height: 1.4;
	background: #c00;
	color: #fff;
	position: fixed;
	bottom: 0;
	left: 0;

	opacity: .24;

	@include media-breakpoint-up( sm ) {
		content: 'sm';
	}
	@include media-breakpoint-up( md ) {
		content: 'md';
	}
	@include media-breakpoint-up( lg ) {
		content: 'lg';
	}
	@include media-breakpoint-up( xl ) {
		content: 'xl';
	}

}
