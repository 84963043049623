#footer-access {
  .menu>ul {
    &>li {
      &+li {
        margin-top: 0;
      }
    }
  }
}

#access {
  padding: 10vh 10vw 20vh;
}

.mc {
  &.w-940-full {
    margin: ( $grid-gutter-width ) ( calc( ( 100vw - ( #{map-get( $container-max-widths, sm )} - #{$grid-gutter-width} ) ) / 2 * -1 ) ) 0;
  }
}

.jumbotron.slider {
  .mc.w-940-full {
    margin: 0;
  }
}

.carousel {
  .mc .legende {
    width: $space-14;
    left: 50%;
    right: 50%;
    transform: translateX( -50% );
  }
}

.mc.slide-main {
  .legende {
    width: 100%;
  }
}
