@function pi() {
  @return 3.14159265359;
}

@mixin themed() {
  @each $theme, $map in $themes {
    $theme-variant: $theme !global;
    @if $theme == default {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get( map-get( $themes, $theme ), '#{$key}' );
        $theme-map: map-merge( $theme-map, ( $key: $value ) ) !global;
      }
      @content;
      $theme-map: null !global;
    }
    @else {
      @media ( prefers-color-scheme: #{$theme} ) {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get( map-get( $themes, $theme ), '#{$key}' );
          $theme-map: map-merge( $theme-map, ( $key: $value ) ) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
}

@mixin dark() {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}
@function tt( $default-value, $dark-value ) {
  @if $theme-variant == default {
    @return $default-value;
  }
  @else {
    @return $dark-value;
  }
}
@function t( $key ) {
  @return map-get( $theme-map, $key );
}
