.mc {

  &.w-700 {
    max-width: 700px;
    width: 700px;
  }

  &.w-940-full {
    margin: ( $grid-gutter-width ) ( calc( ( 100vw - ( #{map-get( $container-max-widths, md )} - #{$grid-gutter-width} ) ) / 2 * -1 ) ) 0;


    .mcf.w-940, .rp-max-940 {
      max-width: none;
    }
  }

}

#access {
  // justify-content: center;

  .menu>ul {
    font-size: 1rem;
    display: flex;
    margin-left: math.div( -$grid-gutter-width, 2 );
    margin-right: math.div( -$grid-gutter-width, 2 );

    &>li {
      flex: 1 1 auto;
      padding-left: math.div( $grid-gutter-width, 2 );
      padding-right: math.div( $grid-gutter-width, 2 );

      &+li {
        margin-top: 0;
      }
      &>a{
        font-size: $font-size-lg;
      }
    }
    a {
      display: block;
      line-height: 1.3;
    }
  }
}

blockquote {
  border-left: none;
  padding-left: 0;
  padding-right: $grid-gutter-width * 2;
  margin: 2rem 0;

  &.important-quote {
    font-size: $h1-font-size;

    cite {
      font-size: $h3-font-size;
    }
  }

  p, cite {
    margin-left: auto;
  }
}

.entries {

  ol.row {
    margin-top: $space-8;
  }
  article>.row {
    align-items: center;
  }

  a {
    transition: all $transition-duration;
    transform: translate( 0, 0 );

    &:hover {
      transform: translate( $space-2, 0 );

      .info {
        transform: translate( -$space-2 * .2, 0 );
      }
    }
  }

  .pict {
    @include make-col( 4 );
    margin-left: -$grid-gutter-width;
    margin-right: $grid-gutter-width;
    margin-top: 0 !important;
  }
  .info {
    @include make-col( 8 );
    padding-bottom: math.div( $grid-gutter-width, 2 );
    margin-top: -1.8rem;
  }
  .footer-info {
    margin-top: $space-6;
  }
  .featured .pict {
    @include make-col( 9 );
    @include make-col-offset( 2 );
    margin-left: $grid-gutter-width * 2;
    margin-bottom: $grid-gutter-width;
  }
  .featured .info {
    @include make-col( 8 );
    @include make-col-offset( 4 );
    margin-top: 0;
    border-left: solid 1px $color-secondary-100;

    @include dark() {
      border-left-color: $color-secondary-800;
    }
  }

  li:nth-child( odd ) {
    .pict {
      order: 1;
      margin-left: $grid-gutter-width;
      margin-right: -$grid-gutter-width;
    }
    .info {
      text-align: right;
      padding-left: math.div( $grid-gutter-width, 2 );
      padding-right: $grid-gutter-width;

      h3,
      .head {
        margin-left: auto;
      }
    }

    .footer-info:before {
      left: inherit;
      right: 0;
    }

    &.featured {
      .pict {
        order: 0;
        @include make-col-offset( 3 );
      }
      .info {
        @include make-col-offset( 0 );
        border-right: solid 1px $color-secondary-100;
        border-left: none;
        margin-top: 0;

        @include dark() {
          border-right-color: $color-secondary-800;
        }
      }
    }
  }
  li + li {
    margin-top: $grid-gutter-width * 2;
    padding-top: 0;
    border: none;
  }
  li:first-child.featured + li {
    margin-top: $grid-gutter-width * 2;
    padding-top: 0;
  }

  &.text-only {
    ol.row {
      margin-top: 0;
    }
    li {
      @include make-col( 6 );
      margin-top: $grid-gutter-width * 2;
    }
    .pict {
      display: none;
    }
    .title {
      margin-left: 0 !important;
    }
    .info {
      @include make-col( 12 );
      text-align: left !important;
      padding-right: $grid-gutter-width * 2;
    }
  }

  .title {
    max-width: $space-13;
  }

  &#meta-nav {
    article {
      width: 100%;
    }
    li {
      @include make-col( 6 );

      .info {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    li:nth-child( even ) .info {
      padding-left: 0;
      padding-right: $grid-gutter-width;
    }
    li:nth-child( odd ) .info {
      padding-right: 0;
      padding-left: $grid-gutter-width;
    }
    li.parent,
    li.previous {
      order: -1;
      display: flex;
    }
    li.parent {
      .info {
        @include make-col( 10 );
        padding-left: math.div( $grid-gutter-width, 2 );
      }
      article {
        width: 100%;
        align-self: center;
      }
    }
  }

}

.jumbo {
  padding-bottom: $space-8;
}

.keep-reading .entries {
  li + li {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
}

.photography-gallery {
  .mc,
  .mc.w-940 {
    margin-left: auto;
    margin-right: auto;
  }
}
